@import "../../../styles/description.scss";

.epolicy {
  @include content;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include mobile {
    grid-template-columns: 1fr;
  }
}

.image {
  width: 461px;
  height: 254px;

  @include mobile {
    order: 2;
    margin-top: 20px;
    width: 343px;
    height: 189px;
  }

  @include mobile-mini {
    width: 288px;
    height: 159px;
  }
}

.title {
  margin-bottom: 42px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.content {
  @include simple-text;
}

.content__row {
  & + & {
    margin-top: 20px;

    @include mobile {
      margin-top: 16px;
    }
  }
}
