@import "../../../styles/description.scss";

.sk_licenses {
  @include content;
}

.title {
  @include h1;

  margin-bottom: 40px;
}

.license__title {
  @include h3;

  margin-bottom: 24px;
}

.license__content {
  margin-bottom: 20px;
}

.license__link {
  @include button-text;
  padding-left: 34px;
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../public/icons/document.svg");
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 26px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
