@import "../../../styles/description.scss";

.container {
  @include content;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include mobile {
    flex-direction: column;
  }

  @include mobile-mini {
    gap: 16px;
  }

  .title {
    @include mobile {
      text-align: left;
    }
  }

  .text {
    max-width: 672px;

    * + * {
      margin-top: 12px;
    }

    @include laptop {
      max-width: 580px;
    }

    .button {
      display: inline-block;
      margin-top: 20px;

      @include mobile {
        margin-top: 12px;
      }
    }
  }
}
