@import "../../../styles/description.scss";

$shadow: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.07));

.sectionContainer {
  @include content;
  display: grid;
  grid-template-areas:
    "title link"
    "cards cards";
  grid-gap: 40px;

  @include mobile {
    grid-template-areas:
      "title"
      "cards"
      "link";
    grid-gap: 20px;
  }

  .title {
    grid-area: title;
  }

  .link {
    grid-area: link;
    justify-self: end;

    @include mobile {
      justify-self: center;
    }
  }

  .cardsContainer {
    grid-area: cards;
    display: flex;
    justify-content: space-between;

    .card {
      position: relative;
      width: 211px;
      height: 300px;
      border-radius: 16px;
      filter: $shadow;
      box-shadow: 0 0 0 1pt var(--bg);

      .cardText {
        position: absolute;
        margin: 24px 16px;
      }

      .cardImage {
        border-radius: 16px;
        z-index: -1;
      }

      @include laptop {
        height: 280px;
      }
    }

    @include mobile {
      display: unset;

      .swiperContainer {
        overflow: visible;
        @include content;
        padding: 2px;
        height: 333px;
        filter: $shadow;

        .card {
          height: 300px;
          width: 211px;
          filter: unset;
          font-size: 16px;
        }

        .cardText {
          font-size: 16px;
        }
      }
    }
  }
}
