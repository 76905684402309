@import "../../../styles/description.scss";

.sectionContainer {
  @include content;

  &.mainPage .text h1 {
    font-size: 40px;
    line-height: 48px;

    @include mobile {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .text {
    span {
      font: inherit;
    }

    h3, h4 {
      margin-top: 16px;
    }
  }

  .buttonContainer {
    text-align: center;

    .button {
      margin-top: 32px;
      display: inline-block;
    }
  }
}
