@import "../../../../../styles/description.scss";

.list_item {
  height: 100%;
  background-color: $color-light-disabled;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.active) {
    &:hover {
      transition: all 0.3s linear;
      background-color: $color-gray-bgc-2;
    }
  }

  &:focus {
    outline: 0;
  }

  h5 {
    @include h5;
  }

  &.border {
    &:not(:first-of-type):not(.active) {
      border-left: 2px solid rgba($color-additional-light, 0.2);
    }
  }

  &.active {
    color: $color-red;
    background-color: $color-white;
    border: 1px solid $color-white;
    border-radius: 10px 10px 0 0;
  }
}

.btn {
  &:focus {
    outline: 0;
  }

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 8px;
    position: relative;
    right: 0;
  }
}

.tab {
  @include h4;
  padding: 0 32px;
  height: 124px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  border-radius: 0 0 10px 10px;
}
