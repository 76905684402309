@import "../../../styles/description.scss";

.wrapper {
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray-bgc-2;
  }
}

.container {
  display: grid;
  grid-gap: 20px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  @include h3;
  margin: 0;
}

.titleIcon {
  margin-left: 8px;
  height: 24px;
  min-width: 21px;
}

.text {
  @include simple-text;
  color: $color-additional;
}
