@import "../../../styles/description.scss";

.container {
  @include content;

  .titlesWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }

    .title {
      width: 460px;
      text-align: center;

      @include laptop {
        width: 394px;
      }

      @include mobile {
        width: 108px;
        @include h3;
      }
    }
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
