@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  background: var(--bg-light);
  border-radius: 16px;
  padding: 40px;

  @include mobile {
    padding: 20px 16px;
  }

  .title {
    @include mobile {
      text-align: left;
    }
  }

  .tabs {
    margin-block: 16px 32px;
    display: grid;
    width: fit-content;
    grid-column-gap: 24px;
    grid-row-gap: 12px;
    grid-template-columns: repeat(2, auto);
    position: relative;
    align-items: end;

    @include mobile {
      margin-block: 16px 12px;
      grid-column-gap: 16px;
      grid-row-gap: 8px;
    }

    .tab {
      cursor: pointer;
      transition-duration: 0.3s;

      @include mobile {
        h3 {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
        }
      }

      &.activeTab {
        color: var(--corporate);
      }

      .tabUnderline {
        visibility: hidden;
      }

      .activeUnderline {
        visibility: unset;
        transition-duration: 0.3s;
        background: var(--corporate);
        border-color: var(--corporate);
        position: relative;
        bottom: -16px;

        @include mobile {
          bottom: -12px;
        }
      }
    }

    .tabUnderline {
      height: 4px;
      grid-column: 1 / span 2;
      border-radius: 2px;
      border: 1px solid var(--additional-light);
      background: var(--additional-light);
    }
  }
}
