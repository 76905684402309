.items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .item {
    display: flex;
    align-items: center;
    gap: 17px;

    .icon {
      width: 40px;
      height: 40px;
      object-fit: none;
    }

    span {
      width: 162px;
    }
  }
}
