@import "../../../styles/description.scss";

.container {
  @include content;
}

.title {
  @include h1;
  margin: 0 0 40px 0;

  @include mobile {
    margin-bottom: 20px;
    text-align: center;
  }
}

.cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  
  @include mobile {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
