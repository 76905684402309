@import "../../../styles/description.scss";

.container {
  width: 100%;

  .slider {
    height: 21px;
    background: transparent;
    width: inherit;
    -webkit-appearance: none;
    cursor: pointer;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 16px + var(--ratio) * (100% - 16px));

    /*webkit*/
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border: 4px solid var(--corporate);
      border-radius: 1em;
      background: var(--white);
      margin-top: calc(max((4px - 1px - 1px) * 0.5, 0px) - 16px * 0.5);
    }

    &::-webkit-slider-runnable-track {
      height: 4px;
      border-radius: 0.5em;
      background: var(--additional);
      box-shadow: none;
    }

    &::-webkit-slider-runnable-track {
      background: linear-gradient(var(--corporate), var(--corporate)) 0 / var(--sx) 100% no-repeat, var(--additional);
    }

    /*mozilla*/
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border-radius: 1em;
      background: var(--white);
      border: 4px solid var(--corporate);
    }

    &::-moz-range-track {
      height: max(calc(4px - 1px - 1px), 0px);
      border-radius: 0.5em;
      background: var(--additional);
      box-shadow: none;
    }

    &::-moz-range-track {
      background: linear-gradient(var(--corporate), var(--corporate)) 0 / var(--sx) 100% no-repeat, var(--additional);
    }

    /*ms*/
    &::-ms-fill-upper {
      background: transparent;
      border-color: transparent;
    }

    &::-ms-fill-lower {
      background: transparent;
      border-color: transparent;
    }

    &::-ms-thumb {
      width: 16px;
      height: 16px;
      border-radius: 1em;
      background: var(--white);
      border: 4px solid var(--corporate);
      margin-top: 0;
      box-sizing: border-box;
    }

    &::-ms-track {
      height: 4px;
      border-radius: 0.5em;
      background: var(--additional);
      box-shadow: none;
      box-sizing: border-box;
    }

    &::-ms-fill-lower {
      height: max(calc(4px - 1px - 1px), 0px);
      border-radius: 0.5em 0 0 0.5em;
      margin: -1px 0 -1px -1px;
      background: var(--corporate);
      border: 1px solid #b2b2b2;
      border-right-width: 0;
    }
  }
}
