@import "../../../../../styles/description";

.items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .item {
    display: flex;
    gap: 17px;

    .icon {
      width: 40px;
      height: 40px;
      object-fit: none;
    }

    .text p {
      margin-block: 8px;
    }

    .button {
      background-color: white;
      width: 200px;

      @include mobile-mini {
        width: 174px;
      }
    }
  }
}
