@import '../../../../styles/description.scss';

.buttons {
  z-index: 2;

  @include laptop {
    grid-area: buttons;
  }

  .title {
    @include h1;

    margin-bottom: 40px;

    @include laptop {
      margin-bottom: 60px;
    }
  }

  .button {
    @include h3;

    cursor: pointer;
    margin-bottom: 16px;
    padding: 26px 0 26px 60px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border: 2px solid $color-gray-bgc-2;
    border-right: none;
    color: $color-additional;
    font-weight: 700;
    line-height: 22px;
    transition: all .3s linear;

    &:hover {
      background: $color-light-disabled;
    }

    @include laptop {
      display: none;
    }

    &.active {
      background: $color-gray-bgc-2;
      color: $color-text-main;
    }
  }
}
