@import "../../../../../styles/description.scss";

.wrapper {
  background: var(--white);
  border-radius: 10px;
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: border-color 200ms;
  border: 1px solid var(--bg);
  width: 325px;

  @include laptop {
    width: 273px;
  }

  @include mobile {
    width: 323px;
    padding: 20px;
  }

  @include mobile-mini {
    width: 280px;
  }

  &:hover {
    border-color: var(--additional-light);
  }
}

.title {
  @include h3;
  padding-bottom: 20px;
}

.image {
  width: 48px;
  height: 48px;
  margin-bottom: 32px;

  @include mobile {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }
}

.description {
  @include little-text;
  word-break: break-word;
  flex: 1;
  padding-bottom: 20px;
}

.showMore {
  @include little-button-text;
  color: $color-red;

  &:hover {
    color: $color-red-hover;
  }

  &:active {
    color: $color-red-active;
  }
}
