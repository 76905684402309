@import "../../../styles/description.scss";

.insurance_company {
  @include content;
}

.image {
  padding: 32px 18px;
  width: 300px;
}
