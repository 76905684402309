@import "../../../styles/description.scss";

.container {
  display: grid;
  grid-row-gap: 40px;

  @include mobile {
    overflow: hidden;
    grid-row-gap: 20px;
    justify-content: center;
  }
}

.content {
  @include content;
}

.slide {
  height: auto;

  @include mobile-mini {
    width: 166px;
  }
}

.desktopCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @include mobile {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 7px;
  }
}

.container {
  &.mobile {
    display: none;
  }

  &.desktop {
    @include mobile-mini {
      display: none;
    }
  }
}

.card {
  [class*="description"] {
    @include mobile {
      display: none;
    }
  }

  [class*="cover"] {
    @include laptop {
      height: 120px;
    }

    @include mobile {
      height: 88px;
    }
  }
}

.title {
  @include h1;

  @include mobile {
    text-align: center;
  }
}

.swiper {
  display: none;
  width: 100%;
  height: 100%;

  @include mobile-mini {
    @include content();
    overflow: visible;
    display: block;
    padding-bottom: 27px !important;
  }
}
