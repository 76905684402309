@import "../../../styles/description.scss";

.partners__docs__licenses {
  @include content;

  @include mobile {
    text-align: center;
  }
}

.title {
  @include h2;
  margin-bottom: 20px;
}

.subtitle {
  @include simple-text;
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 12px;
  }
}

.links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 60px;

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0px;
  }
}

.link {
  @include button-text;

  color: $color-red;
  margin-top: 16px;

  @include mobile {
    margin-top: 8px;
  }
}
