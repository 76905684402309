@import "../../../styles/description.scss";

.choose_product{
    background-color: #fff;

    padding: 20px 0 60px;
    max-width:1134px;
    margin: 0 auto;

    @include laptop{
        max-width: 980px;
    }

    @include mobile{
        max-width: 343px;
        padding: 20px 0 40px;
    }

    @include mobile-mini {
        max-width: 288px;
    }
}

.title{
    @include h1;
    margin-bottom: 40px;

    @include mobile{
        text-align: center;
        margin-bottom: 20px;
    }
}

.cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include mobile{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}