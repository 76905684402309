@import "../../../styles/description.scss";

$mobile-net-width: 343px;
$mobile-mini-net-width: 288px;
$mobile-max-form-width: 375px;
$mobile-mini-max-form-width: 320px;

.container {
  @include content;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column-reverse;
      margin-bottom: 24px;
    }

    .textBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 12px;
      min-width: 500px;

      @include laptop {
        min-width: 500px;
      }

      @include mobile {
        min-width: 100%;
        width: 100%;
        text-align: start;
        gap: 12px 8px;
      }

      .title {
        position: relative;
        text-align: left;
        width: 100%;

        @include mobile {
          padding-right: 32px;
        }
      }

      .iconSber {
        position: relative;
        left: 16px;
        bottom: -10px;

        @include mobile {
          width: 24px;
          height: 24px;
          transform: scale(0.75);
          overflow: visible;
          left: 8px;
          bottom: 2px;
        }
      }
    }

    .image {
      width: 576px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .formWrapper {
    padding: 40px 48px;
    box-shadow: 0 0 20px rgba(28, 41, 61, 0.05), 0 12px 20px rgba(28, 41, 61, 0.06);
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    @include mobile {
      position: relative;
      left: calc((min(#{$mobile-max-form-width}, 100vw) - #{$mobile-net-width}) / -2);
      width: calc(min(100vw, #{$mobile-max-form-width}));
    }

    @include mobile-mini {
      left: calc(min((min(#{$mobile-mini-max-form-width}, 100vw) - #{$mobile-mini-net-width}) / -2, 0px));
      width: calc(min(100vw, #{$mobile-mini-max-form-width}));
    }

    .chips {
      grid-area: buttons;
      display: flex;
      gap: 8px;
      margin-block: 16px 24px;
      flex-wrap: wrap;

      @include mobile {
        margin-block: 12px 20px;
        gap: 6px;
      }
    }

    @include mobile {
      padding: 24px 16px;
    }
  }
}
