@mixin h1 {
  font: {
    size: 30px;
    weight: 700;
  }
  line-height: 40px;

  @include mobile {
    font: {
      size: 20px;
    }
    line-height: 28px;
  }
}

@mixin h2 {
  font: {
    size: 24px;
    weight: 700;
  }
  line-height: 32px;

  @include mobile {
    font: {
      size: 18px;
    }
    line-height: 24px;
  }
}

@mixin h3 {
  font: {
    size: 18px;
    weight: 700;
  }
  line-height: 24px;

  @include mobile {
    font: {
      size: 14px;
    }
    line-height: 20px;
  }
}

@mixin h4 {
  font: {
    size: 16px;
    weight: 600;
  }
  line-height: 20px;

  @include mobile {
    font: {
      size: 14px;
    }
  }
}

@mixin h5 {
  font: {
    size: 14px;
    weight: 700;
  }
  line-height: 20px;

  @include mobile {
    font: {
      size: 12px;
    }
    line-height: 16px;
  }
}

@mixin simple-text {
  font: {
    size: 16px;
    weight: 400;
  }
  line-height: 20px;

  @include mobile {
    font: {
      size: 14px;
    }
  }
}

@mixin lid-title {
  font: {
    size: 16px;
    weight: 600;
  }
  line-height: 24px;
}

@mixin button-text {
  font: {
    size: 16px;
    weight: 600;
  }
  line-height: 24px;
}

@mixin big-link {
  font: {
    size: 20px;
    weight: 600;
  }
  line-height: 28px;
}

@mixin ultra-little-text {
  font: {
    size: 10px;
    weight: 400;
  }
  line-height: 12px;
}

@mixin input-text {
  font: {
    size: 14px;
    weight: 500;
  }
  line-height: 20px;
}

@mixin error-text {
  font: {
    size: 10px;
    weight: 400;
  }
  line-height: 12px;
}

@mixin little-button-text {
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 20px;
}

@mixin little-link {
  font: {
    size: 12px;
    weight: 400;
  }
  line-height: 16px;
}

@mixin little-text {
  font: {
    size: 12px;
    weight: 400;
  }
  line-height: 16px;
}

@mixin semibold-simple-text {
  font: {
    size: 16px;
    weight: 600;
  }
  line-height: 20px;

  @include mobile {
    font: {
      size: 14px;
    }
  }
}

@mixin input-title {
  font: {
    size: 10px;
    weight: 400;
  }
  line-height: 12px;
}

@mixin number-text {
  font: {
    size: 24px;
    weight: 600;
  }
  line-height: 32px;
}

@mixin markdown {
  display: grid;
  grid-row-gap: 20px;

  h1 {
    @include h1;
    margin-top: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  p {
    @include simple-text;
    width: 100%;
  }

  a {
    @include button-text;
    color: $color-red;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  ul {
    li {
      @include simple-text;
      position: relative;
      margin-left: 16px;
      grid-area: list;

      &:before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        top: 50%;
        left: -16px;
        transform: translate(0, -50%);
        border-radius: 50%;
        background-color: $color-blue-primary;
      }

      @include mobile {
        text-align: left;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  ol {
    list-style: decimal;
    margin-left: 16px;

    li {
      @include simple-text;

      @include mobile {
        text-align: left;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  p {
    @include simple-text;
  }

  a {
    @include button-text;
    color: $color-red;

    &:hover {
      color: $color-red-hover;
    }
  }
}
