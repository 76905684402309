@import "../../../../../styles/description.scss";

.wrapper {
  display: flex;
  border-radius: 10px;
  border: 1px solid $color-gray-bgc-2;
  overflow: hidden;
}

.cover {
  height: 100%;
  width: 268px;
  object-fit: cover;

  @include mobile {
    display: none;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  background: $color-white;

  @include laptop {
    padding: 20px;
  }
}

.title {
  @include h3;

  min-height: 48px;

  @include mobile {
    text-align: center;
    min-height: 0;
  }
}

.link {
  @include big-link;

  color: var(--corporate);

  @include mobile {
    text-align: center;
  }
}

.icons {
  gap: 16px;
  display: flex;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
  }

  @include mobile {
    min-height: 0;
    justify-content: center;

    &.hidden {
      display: none;
    }
  }
}
