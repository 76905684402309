@import "../../../styles/description.scss";

$pagination-buttons-size: 32px;
$arrow-offset: 2px;

.sectionContainer {
  @include content;
  display: grid;
  grid-template-areas:
    "title"
    "swiper"
    "arrows";
  grid-gap: 20px;

  @include mobile {
    grid-template-areas:
      "title"
      "swiper";
  }

  .title {
    grid-area: title;
    text-align: start;

    @include mobile {
      text-align: center;
    }
  }

  .arrows {
    grid-area: arrows;
    display: grid;
    justify-content: center;
    align-content: center;
    gap: 8px;
    grid-auto-flow: column;

    .arrow {
      width: $pagination-buttons-size;
      height: $pagination-buttons-size;
      background-color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 0 0 0 1pt var(--bg);
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }

      &Disabled svg {
        fill: var(--additional-light);
      }

      &Prev {
        padding-right: $arrow-offset;
      }

      &Next {
        padding-left: $arrow-offset;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .swiperContainer {
    padding: 1px;
    grid-area: swiper;
    @include content;

    @include laptop {
      overflow: visible;
    }

    @include mobile {
      display: flex;
      flex-direction: column-reverse;

      > * {
        position: unset;
      }

      &Pagination {
        margin-top: 20px;
      }
    }

    .slide {
      width: 364px;
      height: auto;
      border-radius: 10px;
      padding: 16px 20px;
      box-shadow: 0 0 0 1pt var(--bg);
      background-color: var(--white);
      display: flex;
      flex-direction: column;

      @include mobile {
        width: 308px;

        @include mobile-mini {
          width: 260px;
        }
      }

      &Top {
        display: flex;
        justify-content: space-between;

        .rating,
        svg {
          width: 85.34px;
          height: 12.67px;
        }
      }

      &Middle {
        margin-bottom: 16px;
      }

      &ReviewTitle {
        padding-block: 20px 12px;
        border-top: solid 1px var(--additional-light);
      }
    }
  }
}
