@import "../../../../../styles/description";

.title {
  text-align: center;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .item {
    display: flex;
    justify-content: space-between;
    height: 176px;
    padding: 20px 24px;

    @include mobile-mini {
      padding-inline: 16px;
    }

    .chip {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      background: var(--corporate);
      padding: 3px 18px;
      border-radius: 12px;
      height: 26px;
    }

    .icon {
      align-self: flex-end;
    }
  }
}
