@import "../../../styles/description.scss";

.wrapper {
  display: grid;
  grid-gap: 32px;
  padding: 32px;
  border: 1px solid $color-gray-bgc-2;
  background: $color-white;
  border-radius: 10px;
  width: 269px;
  height: 272px;

  @include laptop {
    width: 230px;
    height: 268px;
  }

  @include mobile {
    grid-gap: 20px;
    width: 168px;
    height: 192px;
    padding: 20px;
  }
}

.iconContainer {
  min-width: 48px;
  min-height: 48px;
  border-radius: 10%;
  background: $color-pink-light;
  justify-self: start;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    min-width: 32px;
    min-height: 32px;
  }
}

.icon {
  width: 24px;
  height: 24px;

  @include mobile {
    width: 20px;
    height: 20px;
  }
}

.title {
  @include h3;
  margin: 0;
}

.link {
  @include little-button-text;
  color: $color-red !important;
}
