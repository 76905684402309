@import "../../../styles/description.scss";

.wrapper {
  padding: 40px 0 60px 0;

  @include mobile {
    padding: 20px 0 40px 0;
  }
}

.container {
  @include content;
  display: grid;
  justify-content: space-between;
  grid-template-areas:
    'title link'
    'text text';
  grid-row-gap: 40px;

  @include mobile {
    grid-template-areas:
      'title'
      'text'
      'link';
    justify-content: center;
    grid-row-gap: 20px;
  }

  &.hideLink {
    grid-template-areas:
    'title'
    'text';

    @include mobile {
      grid-template-areas:
      'title'
      'text'
    }
  }
}

.title {
  @include h1;
  margin: 0;
  grid-area: title;

  @include mobile {
    text-align: center;
  }
}

.link {
  grid-area: link;
  justify-self: end;

  @include mobile {
    justify-self: center;
  }
}

.text {
  @include simple-text;
  grid-area: text;

  @include mobile {
    text-align: center;
  }
}
