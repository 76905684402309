@import "../../../../../styles/description";

.card {
  border-radius: 8px;
  box-shadow: var(--shadow1);
  padding: 24px 40px;

  @include mobile {
    padding: 20px 16px;
  }

  .title {
    display: flex;
    gap: 12px;
    align-items: center;

    @include mobile {
      gap: 8px;
      flex-wrap: wrap;

      .disclosureButton {
        white-space: nowrap;
        overflow: hidden;

        :first-child {
          max-width: 68px;
          overflow: hidden;
        }
      }
    }

    .logo {
      width: 195px;
      height: 80px;
      padding: 16px 12px;
      border: 1px solid var(--bg);
      border-radius: 8px;

      @include mobile {
        width: 140px;
        height: 64px;
      }
    }

    .description {
      @include mobile {
        order: 3;
      }

      .text {
        margin-top: 8px;

        @include mobile {
          margin-top: 4px;
        }

        span {
          @include input-text();

          @include mobile {
            display: block;
          }
        }
      }

      .littleText {
        margin-top: 2px;

        @include mobile {
          margin-top: 4px;
        }
      }
    }
  }

  .divider {
    margin-block: 40px;
  }

  .title + .divider {
    margin-top: 24px;
  }

  .section {
    display: grid;
    grid-template-columns: 48px 420px auto;
    grid-template-areas: "icon title text" "none none text";
    grid-column-gap: 12px;
    align-items: start;

    @include laptop {
      grid-template-columns: 48px 316px auto;
    }

    @include mobile {
      grid-template-columns: 48px auto;
      grid-template-areas: "icon title" "text text";
      grid-row-gap: 20px;
    }

    .icon {
      grid-area: icon;
      width: 48px;
      height: 48px;
      background: var(--bg-light);
      border: 1px solid var(--bg);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      grid-area: title;
      padding-right: 60px;
      align-self: center;

      @include laptop {
        padding-right: 20px;
      }

      @include mobile {
        align-self: center;
      }
    }

    .text {
      grid-area: text;

      h3 {
        margin-bottom: 16px;

        @include mobile {
          margin-bottom: 12px;
        }
      }

      ul + h3 {
        margin-top: 40px;

        @include mobile {
          margin-top: 20px;
        }
      }

      h4 {
        margin-bottom: 12px;
      }

      * + h4 {
        margin-top: 16px;

        @include mobile {
          margin-top: 12px;
        }
      }

      sub {
        @include little-text();
        color: var(--additional);
      }

      ul {
        list-style-position: outside;
      }

      li {
        margin-left: 20px;
      }
    }
  }
}

.text * {
  margin: 0;
}
