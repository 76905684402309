@import "../../../styles/description.scss";

.advantages {
  padding: 60px 0;
  margin: 0 auto;
  max-width: 1134px;

  @include laptop {
    max-width: 980px;
  }

  @include mobile {
    max-width: 343px;
    padding: 40px 0;
  }

  @include mobile-mini {
    max-width: 288px;
  }

  .grid {
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 52px;
    grid-template-areas:
      "title iconlink"
      "cards cards";
    justify-content: space-between;

    @include mobile {
      grid-row-gap: 20px;
      grid-template-columns: unset;
      grid-template-areas:
        "title"
        "slider"
        "iconlink";
      overflow: hidden;
    }
  }
}

.columns {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-area: cards;
}

.slider {
  grid-area: slider;
  max-width: 343px;

  @include mobile-mini {
    max-width: 280px;
  }
}

.title {
  @include h1;
  grid-area: title;

  @include mobile {
    text-align: center;
  }
}

.iconLink {
  width: 85px;
  grid-area: iconlink;
  justify-self: end;

  @include mobile {
    justify-self: center;
  }
}
