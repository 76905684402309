@import "../../../styles/description.scss";

.wrapper {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid $color-gray-bgc-2;
  background-color: $color-white;

  @include mobile {
    width: 168px;
    height: 100%;
  }

  &:hover {
    border-color: $color-additional-light;

    a {
      color: $color-red-hover;
    }
  }
}

.cover {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 140px;

  @include mobile {
    height: 70px;
  }
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoContainer {
  padding: 32px;
  display: grid;
  grid-row-gap: 20px;

  .title {
    @include h3;

    color: $color-text-main;
  }

  .description {
    @include little-text;
    word-break: break-word;

    color: $color-text-main;
  }

  @include mobile {
    padding: 20px;
  }
}

.link {
  padding: 0 0 32px 32px;
  @include little-button-text;
  color: $color-red;

  @include mobile {
    padding: 0 0 20px 20px;
  }
}


