@import "../../../../../../../styles/description.scss";

.container {
  border: 1px solid var(--bg);
  border-radius: 10px;
  height: 100%;
  width: 269px;
  background-color: var(--white);
  overflow: hidden;

  @include laptop {
    width: 230px;
  }

  @include mobile {
    width: 168px;
  }

  @include mobile-mini {
    width: 100%;
  }

  .link {
    width: inherit;
    height: inherit;
    box-sizing: border-box;
  }

  .imageWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 91px;
    object-fit: cover;
    object-position: right;

    @include laptop {
      height: 78px;
    }

    @include mobile {
      height: 58px;
    }
  }

  .date {
    color: var(--additional);
    margin-bottom: 4px;
  }

  .description {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include mobile {
      padding: 16px 20px;
    }

    @include mobile-mini {
      padding: 12px 45px 36px 12px;
    }
  }
}
