@import "../../../styles/description.scss";

.wrapper {
  position: relative;
  overflow-y: hidden;
}

.content {
  @include content;

  padding-top: 60px;

  @include mobile {
    padding-top: 40px;
  }
}

.item {
  display: flex;
  margin-bottom: 120px;

  &:last-of-type {
    margin-bottom: 60px;
  }

  @include mobile {
    margin-bottom: 80px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .item__content {
    display: flex;
    @include mobile {
      display: block;
    }
  }
}

.year {
  @include h4;
  background: var(--primary);
  color: var(--white);
  padding: 10px 14px;
  width: fit-content;
  border-radius: 10px;
  height: fit-content;
  margin-right: 32px;
  z-index: 1;

  @include mobile {
    margin-right: 24px;
  }

  @include mobile-mini {
    margin-right: 16px;
  }
}

.title {
  display: flex;
  .text {
    @include h1;
    margin-left: 52px;
    padding-bottom: 40px;

    @include mobile {
      margin-left: 38px;
      padding-bottom: 20px;
    }

    @include mobile-mini {
      margin-left: 30px;
    }
  }

  .vertical_line {
    all: unset;
    margin-right: 34px;
    background: var(--primary);
    width: 12px;
    position: relative;
    top: 1px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    transform: translateX(calc(50% + 16px));
  }
}

.description {
  @include simple-text;
  color: var(--main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p + p {
    margin-top: 20px;
  }

  @include mobile {
    justify-content: unset;

    p + p {
      margin-top: 16px;
    }
  }
}

.vertical_line {
  background: var(--primary);
  width: 12px;
  height: 100%;
  position: absolute;
  transform: translateX(calc(50% + 16px));
}

.image {
  margin-right: 20px;
  width: 557px;

  @include laptop {
    width: 400px;
  }

  @include mobile {
    width: 257px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  @include mobile-mini {
    width: 216px;
  }
}
