@import "../../../../styles/description.scss";

.container {
  display: grid;
  grid-gap: 16px;
}

.row {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1.75fr;

  @include mobile {
    grid-template-columns: 1fr;
  }
}