@import "../../../styles/description.scss";

.sectionContainer {
  overflow: hidden;
  border: 1px solid var(--bg);

  .swiper {
    @include content;
    margin-block: 16px;

    @include mobile {
      margin-block: 24px;
      height: 90px;
    }

    .swiperContainer {
      overflow: visible;

      > div {
        transition-timing-function: linear;
      }
    }

    .swiperSlide {
      width: 180px;

      @include mobile {
        width: 60px;
        height: 45px;

        &:nth-child(even) {
          margin-top: 45px;
        }
      }
    }

    .slide {
      width: 132px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;

      @include mobile {
        width: 109px;
        height: 45px;
      }

      .slideImg {
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
      }
    }
  }
}
