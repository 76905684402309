@import "../../../styles/description.scss";

.sectionContainer {
  @include content;

  .mainTitle {
    padding-bottom: 40px;
    @include mobile{
      padding-bottom: 20px;
    }
  }
  .title {
    padding-bottom: 50px;
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 156px);
    justify-content: space-between;
    grid-row-gap: 4px;

    @include mobile {
      grid-template-columns: repeat(2, 153px);
    }

    .cardContainer {
      height: 40px;
      display: flex;

      .imageContainer {
        display: flex;
        align-content: center;
        justify-content: center;

        .image {
          width: 40px;
          height: 40px;

          @include mobile-mini {
            max-width: 120px;
          }
        }
      }

      .name {
        @include simple-text;
        margin-left: 8px;
        align-self: center;
        color: $color-text-main;
      }
    }
  }
}



