@import "../../../../../styles/description.scss";

.divider {
  margin-top: 24px;
}

.offerWrapper {
  width: 100%;
  user-select: none;
  align-items: end;
  display: grid;
  grid-auto-columns: min-content;
  grid-template-columns: repeat(2, min-content) auto;
  margin-top: 24px;
  flex-wrap: wrap;
  row-gap: 12px;

  > * {
    white-space: nowrap;
  }

  @include mobile {
    grid-template-columns: repeat(2, min-content) auto;
  }

  .oldCost {
    text-decoration-line: line-through;
  }

  .cost {
    margin-inline-end: 8px;
    font-size: 40px;

    @include mobile {
      line-height: 40px;
      font-size: 32px;
    }
  }

  .currency {
    position: relative;
    bottom: 1px;

    @include mobile {
      bottom: 7px;
    }
  }

  .discount {
    display: inline;
    box-sizing: border-box;
    margin-left: 12px;
    height: 32px;
    padding: 6px 12px;
    background: var(--success);
    border-radius: 30px;

    @include mobile {
      margin-inline: 0 auto;
      grid-column: span 3;
    }
  }

  .button {
    margin-left: auto;
    width: 316px;

    @include mobile {
      margin-left: 0;
      margin-top: 12px;
      width: 100%;
      grid-column: span 3;
    }
  }
}
