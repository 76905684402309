@import "../../../styles/description.scss";

.contact_us {
  @include content;

  margin-bottom: 60px;

  @include mobile {
    margin-bottom: 40px;
  }
}

.title {
  @include h1;
  margin-bottom: 40px;

  @include mobile {
    text-align: center;
    margin-bottom: 20px;
  }
}

.content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);

  @include mobile {
    grid-template-columns: 1fr;
  }
}
