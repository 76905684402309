@import "../../../styles/description.scss";

.wrapper {
  background-color: $color-white;
}

.faq {
  @include content;

  padding: 60px 0;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 40px 0;
  }
}

.title {
  @include h1;

  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
    text-align: center;
  }
}

.content {
  margin-bottom: 40px;

  p {
    margin-bottom: 16px;
  }
  li {
    margin-bottom: 8px;
  }
}

.button {
  align-self: center;
  max-width: 189px;

  @include mobile {
    max-width: 100%;
  }
}
