@import "../../../styles/description.scss";

.container {
  @include content
}

.title {
  @include h2;
  margin: 0 0 40px 0;

  @include mobile {
    text-align: center;
    margin-bottom: 20px;
  }
}

.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 365px 364px 365px;
  justify-content: space-between;
  
  @include laptop {
    grid-template-columns: 315px 311px 314px;
  }
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;

  h3 {
    @include h3;
    margin: 0;
  }

  @include mobile {
    gap: 16px;
    align-items: center;
  }

  &.middle {
    h3 {
      visibility: hidden;

      @include mobile {
        display: none;
      }
    }

    @include mobile {
      margin-top: 16px;
      margin-bottom: 32px;
    }

    @include mobile-mini {
      margin-bottom: 20px;
    }
  }

  &.last {
    gap: 20px;

    @include mobile {
      gap: 16px;
    }

    @include mobile-mini {
      text-align: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include mobile {
        gap: 16px
      }
    }
  }
}

.appLinks {
  display: flex;
  gap: 8px;
  height: 48px;
}

.icon {
  height: 48px;
}

.link {
  justify-self: end;
}