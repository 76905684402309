@import "../../../styles/description.scss";

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 60px;

  @include mobile {
    padding-inline: 16px;
    flex-direction: column-reverse;
    gap: 28px;
    margin-bottom: 40px;
  }

  .text {
    @include mobile {
      * {
        text-align: center;
      }
    }

    * {
      margin-bottom: 0;
    }

    p {
      margin-top: 12px;
    }
  }
}

.image {
  width: 396px;
  height: 240px;

  @include mobile {
    width: 100%;
    height: unset;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
