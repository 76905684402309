@import '../../../../../styles/description.scss';

.slide {
  display: flex;
  gap: 103px;
  align-items: center;
  margin-left: 97px;

  @include mobile {
    display: block;
    margin-left: 0;
  }
}

.content {
  max-width: 375px;
}

.frame {
  @include mobile {
    height: 190px;
    overflow: hidden;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
