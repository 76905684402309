@import "../../../../styles/description.scss";

.tabs {
  width: 1134px;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.07));
  margin: 0 auto;
  overflow: hidden;

  @include laptop {
    width: 980px;
  }

  .list {
    cursor: pointer;
    background-color: $color-light-disabled;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    height: 48px;
    overflow: hidden;
  }

  .list__none {
    display: none;
  }
}
