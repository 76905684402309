@import "../../../../../../styles/description.scss";

.container {
  display: flex;
  gap: 20px;
  align-items: stretch;

  @include mobile {
    flex-direction: column;
    br {
      display: none;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card {
      height: 168px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      padding-left: 32px;

      @include mobile {
        padding-left: 16px;
        justify-content: space-between;
        align-items: stretch;
        height: 164px;

        &:first-child {
          height: 148px;

          .title {
            width: 146px;
          }
        }

        .description {
          width: 166px;
        }
      }

      @include mobile-mini {
        height: 172px;

        &:first-child {
          height: 144px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: auto 0;

        @include mobile {
          gap: 12px;
        }
      }

      img {
        width: 325px;

        @include laptop {
          width: 202px;
          object-fit: cover;
          height: 100%;
        }

        @include mobile {
          width: 141px;
          object-fit: fill;
        }

        @include mobile-mini {
          width: 105px;
        }
      }
    }
  }

  .rightBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--white);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    width: 365px;
    padding: 0 24px;
    flex-shrink: 0;

    .price {
      margin-top: 8px;
      margin-bottom: 24px;
      display: flex;
      align-items: baseline;

      .policePrice {
        @include mobile {
          font-size: 30px;
        }
      }
    }

    .slider {
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    @include mobile {
      padding: 32px 16px;
      width: 100%;
    }
  }
}
