@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  grid-gap: 20px;

  .partnerCardWrapper {
    display: block;
    height: 134px;

    .partnerCard {
      margin-bottom: 8px;
      width: 268px;
      height: 110px;
      border: 1px solid var(--bg);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border-radius: 10px;

      @include laptop {
        width: 230px;
      }

      @include mobile {
        padding: 4px 8px;
        width: 100%;
        height: 70px;
      }

      @include mobile-mini {
        height: 58px;
      }

      .partnerImg {
        width: 200px;

        @include mobile {
          width: 100%;
          max-width: 140px;
          max-height: 56px;
        }

        @include mobile-mini {
          max-width: 120px;
          max-height: 48px;
        }
      }
    }

    .cardTitle {
      text-align: center;
    }

    @include mobile {
      height: 94px;
    }

    @include mobile-mini {
      height: 82px;
    }
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
  }
}
