@import "./breakpoints.scss";

@mixin content {
  // 1440+
  width: 100%;
  max-width: 1134px;
  margin: 0 auto;

  @include laptop {
    // 1439 -980
    max-width: 980px;
  }

  @include mobile {
    // 979-343
    max-width: 343px;
  }

  @include mobile-mini {
    // < 342
    max-width: 288px;
  }
}
