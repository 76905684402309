@import "../../../../styles/description.scss";

.container {
  display: grid;
  grid-gap: 16px;
}

.row {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;

  @include mobile {
    grid-template-columns: 1fr;
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxWithTooltip {
  display: flex;
  align-items: center;
  gap: 7px;

  p {
    @include little-text;
  }

  h4 {
    @include h4;
  }
}

.desktop {
  @include mobile {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.nextButton {
  width: 130px;
  justify-self: end;
}

.information {
  a {
    color: $color-red;
    @include simple-text;
    font-weight: 600;

    &:hover {
      color: $color-red-hover
    }

    &:active {
      color: $color-red-active
    }
  }
}