@import "../../../styles/description.scss";

.card {
  text-decoration: none;

  .container {
    max-width: 100%;
    padding: 0 20px;
    height: 88px;
    display: flex;
    align-items: center;
    background: $color-white;
    border: 1px solid $color-gray-bgc-2;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: $color-light-disabled;
    }

    @include mobile {
      padding: 0 12px;
      height: 72px;
    }
  }

  .iconWrapper {
    min-width: 48px;
    min-height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    background: $color-gray-bgc-2;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      min-width: 24px;
      min-height: 24px;
      margin-right: 12px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;

    @include mobile {
      width: 12px;
      height: 12px;
    }
  }

  .text {
    @include h5
  }
}

.mainInsuranceCases {
  @include content;

  .title {
    @include h2;
    margin-bottom: 40px;

    @include mobile {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
    }
  }

}