@import "../../../../../styles/description.scss";

.container {
  display: flex;

  .card {
    display: flex;
    min-height: 100px;
    background: var(--white);
    width: 100%;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    overflow: hidden;

    @include mobile {
      min-height: 80px;
    }

    .title {
      @include mobile {
        @include h5;
      }

      @include mobile-mini {
        @include ultra-little-text;
      }

      &.left {
        color: var(--additional);
      }

      &.right {
        @include mobile {
          width: 100%;
          color: var(--white);
        }
      }
    }
  }

  .left {
    align-items: center;
    justify-content: center;
    text-align: center;

    @include mobile {
      padding: 0 7px;
    }
  }

  .subject {
    width: 212px;
    align-items: center;
    display: flex;
    flex-shrink: 0;

    @include laptop {
      width: 186px;
    }

    @include mobile {
      width: unset;

      .line {
        display: none;
      }
    }

    .line {
      height: 12px;
      background: var(--white);
      width: 100%;

      &.right {
        background: var(--corporate);
      }
    }

    .circle {
      background: var(--corporate);
      color: var(--white);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      @include mobile {
        margin: 0 8px;
        width: 72px;
        height: 72px;
      }
    }
  }

  .right {
    @include mobile {
      background: var(--corporate);
    }

    .iconWrapper {
      background: var(--corporate);
      width: 96px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      @include mobile {
        display: none;
      }
    }

    .titleWrapper {
      display: flex;
      align-items: center;
      padding: 0 24px;

      @include mobile {
        padding: 12px 7px;
        text-align: center;
        width: 100%;
      }
    }
  }
}
