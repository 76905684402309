@import "../../../../../../../styles/description.scss";

.container {
  background: var(--white);
  border: 1px solid var(--bg);
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 320px;
  height: 100%;

  @include mobile {
    height: 100%;
    width: 100%;
  }

  &.wide {
    @include mobile {
      min-height: 230px;
      width: 100%;

      .imageWrapper {
        height: 160px;
      }
    }
  }

  .imageWrapper {
    position: relative;
    height: 118px;

    @include mobile {
      height: 104px;
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 32px;

    @include mobile {
      margin-top: 20px;
    }

    &.withImage {
      margin-top: 20px;

      @include mobile {
        margin-top: 12px;
      }
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 30px;

      .title {
        @include h4;
      }

      @include laptop {
        margin-bottom: 12px;
      }

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }

  &.withImage {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 90px;
    background: url("./img/arrows.svg");
  }
}
