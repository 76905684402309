@import "../../../../../styles/description.scss";

.slide {
  position: relative;
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  @include mobile {
    height: 250px;
  }
}

.title {
  margin: 0 0 16px 0;
  max-width: 450px;

  @include mobile {
    max-width: 194px;
    margin-bottom: 12px;
  }
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.subtitle {
  @include simple-text;

  max-width: 500px;
  color: $color-additional;
  margin-bottom: 16px;

  @include mobile {
    max-width: 194px;
    margin-bottom: 12px;
  }
}

.link {
  @include little-button-text;
  color: $color-red;

  &:hover {
    color: $color-red-hover;
  }

  &:active {
    color: $color-red-active;
  }
}

.inner {
  @include content;
  z-index: 1;
}
