@import "../../../styles/description.scss";

.container {
  position: relative;
  height: 400px;

  @include mobile {
    height: 250px;
  }
}

.content {
  @include content;
  position: relative;
}

.breadcrumbs {
  position: absolute;
  top: 32px;
  z-index: 2;

  @include mobile {
    top: 0;
  }
}

.pagination__container {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;

  @include mobile {
    bottom: 16px;
  }
}

.pagination {
  &_bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e5e5e5;
    margin: 0 5px;
    cursor: pointer;

    &_active {
      background: #c5c7c9;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;

  @include mobile {
    justify-content: center;
  }
}

.arrow {
  height: 100%;
  width: 40px;
  z-index: 2;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s linear;

  @include mobile {
    display: none;
  }

  svg {
    fill: var(--main);
  }

  &:hover,
  &:focus {
    background-color: rgba(#282d37, 0.1);
  }
}

.arrow__prev {
  left: 0;
}

.arrow__next {
  right: 0;
}
