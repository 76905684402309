@import '../../../../styles/description.scss';

.description {
  margin-left: 50px;

  @include laptop {
    margin: 0;
  }

  @include mobile {
    text-align: center;
  }

  .text {
    h2 {
      @include h2;
      margin-bottom: 20px;
    }

    p {
      @include simple-text;
    }

    @include laptop {
      @include h3;
    }
  }
}
