@import "../../../styles/description.scss";

.container {
  display: flex;
  padding: 16px;
  width: 100%;
  gap: 16px;
  align-items: center;
  background: $color-light-disabled;
  border-radius: 10px;

  @include mobile {
    align-items: start;
    padding: 8px;
    gap: 8px;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.content {
  @include simple-text
}