@import '../../../styles/description.scss';

.wrapper {
  width: 100%;
  border-radius: 10px;
  background-color: $color-white;
  overflow: hidden;
}

.imageWrapper {
  position: relative;
  span {
    position: unset !important;

    .cover {
      height: unset !important;
      position: relative !important;
    }
  }
}

.container {
  padding: 32px;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin-bottom: 66px;

  .title {
    @include h3;

    color: $color-text-main;
  }

  .description {
    @include simple-text;

    color: $color-text-main;
  }
}
