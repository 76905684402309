@import "../../../styles/description.scss";

.wrapper {
  padding: 60px 0;
  background-color: $color-light-disabled;

  @include mobile {
    padding: 40px 0;
  }
}

.container {
  @include content;
  display: grid;
  grid-gap: 40px;
  grid-template-areas:
    'title link'
    'cards cards';

  @include mobile {
    grid-gap: 20px;
    grid-template-areas:
      'title'
      'cards'
      'link';
  }

  &.hideLink {
    grid-template-areas:
    'title'
    'cards';

    @include mobile {
      grid-template-areas:
      'title'
      'cards'
    }
  }
}

.title {
  @include h2;
  margin: 0;
  grid-area: title;

  @include mobile {
    text-align: center;
  }
}

.link {
  grid-area: link;
  justify-self: end;

  @include mobile {
    justify-self: center;
  }
}

.desktopCards {
  display: grid;
  grid-area: cards;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @include mobile {
    display: none;
  }
}

.swiper {
  display: none;
  width: 100%;
  height: 100%;

  @include mobile {
    display: block;
    padding-bottom: 40px !important;
  }
}
