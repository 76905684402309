@import "../../../styles/description.scss";

.container {
  @include content;
}

.cardsWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mobile {
    margin-top: 20px;
    gap: 8px;
  }
}

.successModal {
  img {
    width: 560px;
    height: 270px;

    @include mobile {
      width: 343px;
      height: 165px;
    }

    @include mobile-mini {
      width: 288px;
      height: 138px;
    }
  }

  .message {
    @include simple-text
  }

  @include mobile {
    text-align: center;
  }
}