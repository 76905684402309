@import "../../../styles/description.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  text-align: center;
}
.image__container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 8px;
  }
}
.image {
  width: 200px;
  height: 200px;

  @include laptop {
    width: 171px;
    height: 171px;
  }

  @include mobile {
    width: 169px;
    height: 169px;
  }

  @include mobile-mini {
    width: 144px;
    height: 144px;
  }
}

.subtitle {
  @include h3;
  max-width: 250px;
  text-align: center;
  margin-bottom: 16px;
}
