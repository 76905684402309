@import "../../../styles/description.scss";

.desktop {
  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;

  @include mobile {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.download_app {
  @include content;
  padding: 60px 0;

  @include mobile {
    padding: 20px 0 40px 0;
  }
}

.title {
  @include h1;
  margin-bottom: 40px;
}

.subtitle,
.description {
  @include simple-text;

  margin-bottom: 40px;
}

.content {
  display: flex;

  &__column {
    width: 50%;
  }
}

.buttons {
  display: flex;
  max-width: 470px;

  @include laptop {
    max-width: 478px;
  }
}

.link {
  border-radius: 10px;
  background-color: #000;
  width: 146px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & + & {
    margin-left: 16px;
  }

  &__apple {
    background-image: url("../../../public/images/download-app/app_apple.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__google {
    background-image: url("../../../public/images/download-app/app_google.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__huawei {
    background-image: url("../../../public/images/download-app/app_huawei.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
