@import "../../../styles/description.scss";

.sk_documents {
  @include content;
}

.section:not(:first-of-type) {
  @include mobile {
    margin-top: 40px;
  }
}

.title {
  @include h1;
  margin-bottom: 40px;

  @include mobile {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}

.subtitle {
  @include h2;
  margin-bottom: 28px;

  @include mobile {
    margin-bottom: 20px;
    text-align: center;
  }
}

.links_block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 16px;
  }
}

.links_block > .link {
  flex: 50% 0;
  padding-right: 20px;
  margin-bottom: 24px;

  @include mobile {
    flex: 100%;
    margin-bottom: 16px;
  }
}

.link {
  @include button-text;
  padding-left: 34px;
  align-self: center;
  // width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../public/icons/document.svg");
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 26px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
