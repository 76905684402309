@import "../../../styles/description.scss";

.container {
  @include content;
  border-bottom: 1px solid $color-gray-bgc-2;
}

.tabs {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.tabs__links {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 40px;
  order: 0;
  white-space: nowrap;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabs__links > a {
  @include h3;

  flex: 1;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s linear;
  border-bottom: 1px solid $color-light-disabled;

  &.activeTab {
    color: $color-blue-primary;
    padding-bottom: 18px;
    border-bottom: 2px solid $color-blue-primary;
  }

  &:hover {
    color: $color-blue-primary;
  }

  @media (hover: none) {
    &:hover {
      color: inherit;
    }
  }
}

.tabs > .about.opened ~ .tabs__links > a[href="#about"],
.tabs > .documents.opened ~ .tabs__links > a[href="#documents"],
.tabs > .contacts.opened ~ .tabs__links > a[href="#contacts"],
.tabs > .licences.opened ~ .tabs__links > a[href="#licences"] {
  color: $color-blue-primary;
  padding-bottom: 18px;
  border-bottom: 2px solid $color-blue-primary;
}

.tabs > div:not(.tabs__links) {
  display: none;
  order: 1;
}

.tabs > div.opened {
  display: block;
}
