@import "../../../styles/description.scss";

.content {
  @include content;

  padding-top: 32px;
}

.paddingBottom {
  padding-bottom: 20px;
}