@import "../../../styles/description.scss";

.sectionContainer {
  @include content;

  .letterSelectorContainer {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 197px) auto;
    grid-row-gap: 4px;
    grid-template-areas:
            "latinLetters    button"
            "cyrillicLetters button";

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-areas:
            "latinLetters"
            "cyrillicLetters"
            "button";
    }

    .cyrillicLettersContainer {
      grid-area: cyrillicLetters;
    }

    .latinLettersContainer {
      grid-area: latinLetters;
    }

    .cyrillicLettersContainer,
    .latinLettersContainer {
      height: 32px;
      display: flex;
      @include mobile {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(10, 20px);
        grid-row-gap: 4px;
        justify-content: space-between;
      }

      .letterSelector {
        @include h2;
        color: $color-additional;
        cursor: pointer;

        @include mobile {
          justify-self: center;
          @include h1;
        }

        @include mobile-mini {
          @include h2;
        }

        &.activeLetter {
          color: $color-red;
        }
      }

      .separator {
        width: 32px;

        &:last-child {
          display: none
        }

        @include mobile {
          display: none
        }
      }
    }

    .buttonContainer {
      grid-area: button;

      @include mobile {
        margin-top: 8px;
        min-height: 16px;
      }

      .resetButton {
        margin-left: auto;
        height: 48px;
        width: 157px;
        position: relative;
        top: -8px;

        @include mobile {
          top: 0;
          width: 100%;
        }
      }
    }
  }

  .contentContainer {
    margin-top: 40px;
    column-count: 4;
    height: max-content;

    @include mobile {
      margin-top: 24px;
      column-count: 2;
    }

    .groupContainer {
      break-inside: avoid;
      display: grid;
      grid-template-columns: 84px 128px;
      padding-bottom: 32px;

      @include mobile {
        grid-template-columns: 54px 112px;
      }

      .groupLetter {
        text-align: center;
        padding-right: 20px;
        overflow: visible;
        color: $color-additional-light;
        line-height: 64px;
        font: {
          size: 80px;
          weight: 700;
        }

        @include mobile {
          line-height: 48px;
          font: {
            size: 50px;
          }
        }
      }

      .groupList {
        @include simple-text;
        display: grid;
        grid-auto-rows: max-content;
        grid-row-gap: 4px;

      }
    }
  }
}



