@import "../../../../styles/description.scss";

.tabContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @include mobile {
    grid-template-columns: unset;
    grid-gap: 16px;
  }

  .card {
    border-radius: 16px;
    background: var(--white);
    padding: 20px 16px;

    ul {
      @include markdown();
      grid-row-gap: 8px;
      margin-top: 8px;

      li {
        display: grid;
        grid-template-columns: 20px auto 34px;
        align-items: center;

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--primary);
        }

        .liText {
          margin-top: 0;
        }

        .tooltip {
          justify-self: end;
        }
      }
    }

    p,
    p > span {
      margin-top: 9px;
      @include little-text();
    }

    span {
      font: inherit;
    }
  }

  .mainCards {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include mobile {
      grid-column: unset;
      grid-template-columns: unset;
      grid-gap: 16px;
    }

    :nth-child(5) {
      grid-column: 1 / span 2;
      padding: 14px 16px;

      @include mobile {
        grid-column: unset;
        padding-block: 20px;
      }

      > * {
        display: inline;
      }

      p {
        margin-left: 2px;
        margin-top: 0;
      }
    }
  }

  .redBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .redCard {
      box-shadow: 0 0 0 1pt var(--corporate);
    }

    .button {
      margin-top: auto;
    }
  }
}
