$color-red: #ff0550;
$color-red-hover: #e60548;
$color-red-active: #cc0440;
$color-red-light: #ff447c;
$color-pink-light: #fff3ee;
$color-white: #fff;
$color-footer-description: #90939c;
$color-footer-bgc-market: #000000;
$color-footer-bgc-social: #555961;
$color-text-main: #2f3542;
$color-gray-bgc-1: #f1f2f6;
$color-gray-bgc-2: #eef1f6;
$color-additional: #90a1ac;
$color-additional-light: #c8d0d6;
$color-link-hover: #383a48;
$color-light-disabled: #f4f6f9;
$color-border: #e5e5e5;
$color-light-grey: #c5c7c9;
$color-gray-disabled: #ebebeb;
$color-grz-focus: #9fc0e6;
$color-blue-primary: #1a76e1;
$color-warning-bg: #fff7e6;
$color-warning: #ffaa05;
