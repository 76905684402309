@import "../../../../../styles/description.scss";

.container {
  .title {
    @include h1;
    @include content;
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .button {
    width: 177px;
    margin: 28px auto 0 auto;
  }
}