@import "../../../../../styles/description.scss";

.container {
  display: grid;
  grid-gap: 16px;
}

.row {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;

  @include mobile {
    grid-template-columns: 1fr;
  }
}

.captcha {
  @include little-text;
  border-left: 2px solid $color-gray-bgc-2;
  color: $color-additional;
  padding-left: 16px;

  a {
    color: $color-red;
    @include little-text;

    &:hover {
      color: $color-red-hover
    }
  }
}

.codeWrapper {
  p {
    margin-top: 16px;
    @include little-link;
    color: $color-light-grey;

    span {
      margin-left: 8px;
      @include little-text;
      color: $color-footer-bgc-market;
    }
  }
}

.information {
  a {
    color: $color-red;
    @include simple-text;
    font-weight: 600;

    &:hover {
      color: $color-red-hover
    }

    &:active {
      color: $color-red-active
    }
  }
}

.codeButton {
  width: 187px;
  height: 48px;
  justify-self: end;
}

.nextButton {
  width: 130px;
  height: 48px;
  justify-self: end;
}