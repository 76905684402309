@import "../../../../styles/description.scss";

.frame {
  z-index: 5;
  width: 313px;
  height: 635px;
  position: relative;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.07));

  @include laptop {
    grid-area: frame;
    align-self: center;
    justify-self: center;
    width: 300px;
    height: 600px;
    filter: none;

    span {
      position: unset !important;
    }
  }

  @include mobile {
    width: 250px;
    height: 507px;

    span {
      position: absolute !important;
    }
  }

  .top {
    width: 150px;
    height: 30px;
    position: absolute;
    background-color: $color-gray-bgc-2;
    border-radius: 0 0 20px 20px;
    border-top: none;
    left: 50%;
    top: 18px;
    transform: translate(-50%);
    z-index: 2;

    @include laptop {
      width: 140px;
      height: 20px;
      top: 16px;
    }

    @include mobile {
      top: 14px;
      width: 130px
    }
  }

  .inner {
    width: calc(100% - 36px);
    height: calc(100% - 38px);
    background-color: transparent;
    margin: 19px 18px 0 18px;
    position: relative;
    z-index: -1;
    overflow: hidden;

    @include laptop {
      top: -3px;
      height: calc(100% - 30px);
    }

    @include mobile {
      top: 17px;
      margin-left: 15px;
      margin-top: 17px;
      width: calc(100% - 30px);
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  background-color: $color-white;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  @include mobile {
    border-radius: 20px;
  }
}
