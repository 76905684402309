@import "../../../styles/description.scss";

.sectionContainer {
  display: grid;
  grid-row-gap: 40px;

  @include mobile {
    grid-row-gap: 20px;
    justify-content: center;
  }
}

.content {
  @include content;
}

.desktopCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.slide {
  height: auto;

  @include mobile-mini {
    &:first-child {
      margin-left: 6px;
    }
  }
}

.container {
  &.mobile {
    display: none;
  }

  &.desktop {
    @include mobile {
      display: none;
    }
  }
}

.title {
  @include h1;

  @include mobile {
    text-align: center;
  }
}

.swiper {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 375px;

  @include mobile {
    display: block;
    padding-bottom: 31px !important;
  }

  @include mobile-mini {
    width: 100vw;
  }
}
