@import "../../../styles/description.scss";

$v3-color-black: #2f3542;
$v3-color-black-2: #000000;
$v3-color-black-3: #383a48;
$v3-color-white: #ffffff;
$v3-color-red: #ff0550;
$v3-color-link-hover: #e60548;
$v3-color-gray: #f2f4f8;
$v3-color-gray-3: #eff1f6;

.europrotocol_template {
  @include content;
}

.v3_section {
  &__content {
    position: relative;
    max-width: 375px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (min-width: 980px) {
      max-width: 980px;
    }

    &_padd {
      @media screen and (min-width: 980px) {
        padding: 0;
        width: 980px;
      }
    }
  }
}

/* text */
.v3_text {
  font-family: "Manrope", sans-serif;
  line-height: 150%;
  color: $v3-color-black;

  &_12 {
    font-size: 12px;
    line-height: 16px;
  }

  &_14 {
    font-size: 14px;
    line-height: 19px;
  }

  &_16 {
    font-size: 16px;
    line-height: 22px;
  }

  &_normal {
    font-weight: 400;
  }

  &_bold {
    font-weight: 600;
  }

  /* desktop text */
  &_d12 {
    @media screen and (min-width: 980px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &_d14 {
    @media screen and (min-width: 980px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &_d16 {
    @media screen and (min-width: 980px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_d_normal {
    @media screen and (min-width: 980px) {
      font-weight: 400;
    }
  }

  &_d_bold {
    @media screen and (min-width: 980px) {
      font-weight: 600;
    }
  }
}
/* /text */

/* color */
.v3_color {
  &_black {
    color: $v3-color-black;
  }

  &_red {
    color: $v3-color-red;
  }
}
/* /color */

.v3_accident {
  &__content {
    background: $v3-color-white;
    border-radius: 15px;

    @media screen and (min-width: 980px) {
      padding: 30px;
    }

    &_padd_bottom {
      @media screen and (min-width: 980px) {
        padding: 30px 30px 0 30px;
      }
    }

    &_padd_side {
      @media screen and (min-width: 980px) {
        padding: 0 30px;
      }
    }
  }

  &_title {
    &__inner {
      padding: 0 20px;

      @media screen and (min-width: 980px) {
        padding: 0;
      }
    }
  }

  &__instruction {
    &_policy {
      width: 311px;
      height: 440px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      &_side_1 {
        width: 311px;
        height: 440px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../../public/images/europrotocol/v3-m-policy-side-face.png");
        position: relative;
        left: calc(50% - 4px);
        transform: translateX(-50%);
        overflow: hidden;
        .v3_accident_tooltip {
          display: block;
        }

        @media screen and (min-width: 980px) {
          width: 978px;
          height: 1386px;

          &:hover {
            .v3_accident_tooltip {
              display: block;
            }
          }
        }

        @media screen and (max-width: 374px) {
          width: 280px;
          height: 390px;
        }
      }

      &_side_2 {
        width: 311px;
        height: 440px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../../public/images/europrotocol/v3-m-policy-side-back.png");
        position: relative;
        left: calc(50% - 4px);
        transform: translateX(-50%);
        .v3_accident_tooltip {
          display: block;
        }

        @media screen and (min-width: 980px) {
          width: 978px;
          height: 1386px;

          &:hover {
            .v3_accident_tooltip {
              display: block;
            }
          }
        }

        @media screen and (max-width: 374px) {
          width: 280px;
          height: 390px;
        }
      }
    }
  }

  &_tooltip {
    display: none;
    position: absolute;
    cursor: pointer;
    height: 25px;
    border: 1px solid $v3-color-red;
    background-color: rgba(255, 5, 80, 0.1);
    border-radius: 5px;
    transition: all 0.25s ease-out;

    &__inner {
      position: absolute;
      left: 45px;
      top: 55px;
      width: 0;
      height: 0;
      background-color: $v3-color-white;
      border-radius: 15px;
      border: none;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1;
      opacity: 0;
      padding: 0;
      overflow: hidden;
      transition: opacity 0.25s ease-out;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);

      &:after {
        content: "";
        position: absolute;
        top: -30px;
        left: 0;
        width: 100%;
        height: 30px;
        display: none;
        z-index: 101;
      }

      &_left {
        left: initial;
        right: 55px;
      }

      &_5 {
        right: 55px;
        top: 50px;
      }

      &_6 {
        right: 40px;
        top: 50px;
      }

      &_7 {
        right: 40px;
        top: 50px;
      }

      &_8_right {
        right: 80px;
        top: 50px;
      }

      &_9_right {
        right: 90px;
        top: 70px;
      }

      &_10_right {
        right: 40px;
        top: -175px;
      }

      &_8 {
        left: 100px;
        top: 55px;
      }

      &_9 {
        left: 110px;
        top: 70px;
      }

      &_10 {
        left: 45px;
        top: -175px;
      }

      &_11 {
        left: 65px;
        top: 52px;
      }

      &_13 {
        left: 122px;
        top: -215px;
      }

      &_14 {
        left: 80px;
        top: 55px;
      }

      &_15 {
        left: 77px;
        top: 55px;
      }

      &_16 {
        left: 105px;
        top: 55px;
      }

      &_17 {
        left: 125px;
        top: 55px;
      }

      &_18 {
        left: 270px;
        top: 55px;
      }
    }

    &:hover {
      overflow: visible;

      &:after {
        display: block;
      }
      & > .v3_accident_tooltip__inner {
        width: 300px;
        height: auto;
        max-height: 500px;
        opacity: 1;
        padding: 30px;
        z-index: 101;
        overflow: visible;

        &:after {
          display: block;
        }
      }
    }

    &_large {
      height: 40px;
    }

    &_1 {
      top: 125px;
      left: 53px;
      width: 87px;
    }

    &_2 {
      top: 162px;
      left: 53px;
      width: 81px;
    }

    &_3 {
      top: 198px;
      left: 53px;
      width: 113px;
    }

    &_4 {
      top: 283px;
      left: 53px;
      width: 126px;
    }

    &_4_right {
      top: 283px;
      left: 630px;
      width: 126px;
    }

    &_5 {
      top: 419px;
      left: 53px;
      width: 117px;
    }

    &_5_right {
      top: 419px;
      left: 630px;
      width: 117px;
    }

    &_6 {
      top: 534px;
      left: 53px;
      width: 97px;
    }

    &_6_right {
      top: 534px;
      left: 630px;
      width: 97px;
    }

    &_7 {
      top: 819px;
      left: 53px;
      width: 94px;
    }

    &_7_right {
      top: 819px;
      left: 630px;
      width: 94px;
    }

    &_8 {
      top: 962px;
      left: 53px;
      width: 198px;
    }

    &_8_right {
      top: 962px;
      left: 660px;
      width: 198px;
    }

    &_9 {
      top: 1083px;
      left: 53px;
      width: 217px;
    }

    &_9_right {
      top: 1083px;
      left: 660px;
      width: 217px;
    }

    &_10 {
      top: 1235px;
      left: 53px;
      width: 92px;
    }

    &_10_right {
      top: 1235px;
      left: 660px;
      width: 92px;
    }

    &_11 {
      top: 252px;
      left: 414px;
      width: 148px;
    }

    &_12 {
      top: 962px;
      left: 440px;
      width: 96px;
    }

    &_13 {
      top: 1182px;
      left: 368px;
      width: 244px;
    }

    &_14 {
      top: 36px;
      left: 405px;
      width: 168px;
    }

    &_15 {
      top: 150px;
      left: 415px;
      width: 150px;
    }

    &_16 {
      top: 504px;
      left: 381px;
      width: 216px;
    }

    &_17 {
      top: 575px;
      left: 352px;
      width: 270px;
    }

    &_18 {
      top: 779px;
      left: 282px;
      width: 420px;
    }
  }
}

.mobile {
  &_active {
    display: block;

    &_flex {
      display: flex;
      @media screen and (min-width: 980px) {
        display: none;
      }
    }

    @media screen and (min-width: 980px) {
      display: none;
    }
  }
}

.desktop {
  &_active {
    display: none;

    @media screen and (min-width: 980px) {
      display: block;
    }
  }
}

@for $i from 1 through 150 {
  .v3_spacer_d#{$i} {
    margin-bottom: $i + px;
  }

  .v3_spacer_m#{$i} {
    margin-bottom: $i + px;
  }
}

.no_bottom_margin {
  margin-bottom: 0 !important;
}
