.title {
  text-align: center;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-block: 20px 8px;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    background: var(--white);
  }
}

.button {
  position: relative;
}
