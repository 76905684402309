@import "../../../../styles/description.scss";

.container {
  position: relative;
  padding-bottom: 110px;

  @include mobile {
    background: var(--bg-light);
    width: 100%;
    padding-bottom: 40px;
  }

  .leadGeneration {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1134px;
    height: 264px;
    background: var(--white);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
    border-radius: 12px;

    @include laptop {
      width: 980px;
    }

    @include mobile {
      height: auto;
      flex-direction: column;
      position: static;
      transform: none;
      @include content;
      background: transparent;
      box-shadow: none;
      gap: 20px;
    }

    .leftPart {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 410px;

      @include laptop {
        width: 365px;
      }

      @include mobile {
        width: 100%;
        flex-direction: column-reverse;
        border-radius: 12px;
        overflow: hidden;
        background: var(--white);
      }

      .content {
        padding: 36px;

        @include laptop {
          padding: 36px 30px;
        }

        @include mobile {
          padding: 24px 20px;

          br {
            display: none;
          }
        }

        p {
          &:first-child {
            margin-bottom: 8px;
          }

          &:last-child {
            @include mobile-mini {
              width: 199px;
            }
          }

          &:nth-child(4n) {
            margin-top: 24px;
            margin-bottom: 12px;

            @include mobile {
              margin-top: 20px;
            }
          }
        }
      }

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--corporate);
        height: 100%;
        width: 100px;
        flex-shrink: 0;

        @include mobile {
          width: 100%;
          height: 72px;
        }

        @include mobile-mini {
          height: 60px;
        }
      }
    }

    .rightPart {
      width: 724px;
      display: flex;
      justify-content: space-between;
      padding: 0 60px;

      @include laptop {
        width: 615px;
        padding: 0 40px;
      }

      @include mobile {
        flex-direction: column;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        background: var(--white);
        padding: 24px 20px;
      }

      .sliderWrapper {
        margin: auto 0;
        width: 380px;
        height: 128px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 2px solid var(--bg);
        padding-right: 63px;

        @include laptop {
          width: 332px;
          padding-right: 40px;
        }

        @include mobile {
          padding: 0;
          border: none;
          width: 100%;
          height: unset;
          margin: 0;
        }

        .sliderTitle {
          @include mobile {
            display: none;
          }
        }

        p:first-child {
          max-width: 195px;
        }

        .slider {
          margin-top: 16px;

          @include mobile {
            margin: 0;
          }
        }
      }

      .buttonWrapper {
        margin: auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .price {
          display: flex;
          align-items: baseline;
          margin-bottom: 12px;
          margin-top: 8px;

          .policePrice {
            @include mobile {
              font-size: 30px;
            }
          }

          @include mobile {
            margin-bottom: 24px;
          }
        }

        .desktopButton {
          @include mobile {
            display: none;
          }
        }
      }

      .mobileButton {
        display: none;
        margin-top: 24px;
        @include mobile {
          display: block;
        }
      }

      @include mobile {
        flex-direction: column-reverse;
      }
    }
  }
}
