@import "../../../styles/description.scss";

.safety {
  background-color: $color-light-disabled;

  width: 100%;
}

.wrapper {
  max-width: 1134px;
  padding: 60px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @include laptop {
    max-width: 980px;
  }

  @include mobile {
    max-width: 343px;
    padding: 40px 0;
  }

  @include mobile-mini {
    max-width: 288px;
  }
}

.image {
  width: 93px;
  height: 85px;
  margin-right: 20px;

  @include mobile {
    display: none;
  }
}

.content {
  @include mobile {
    width: 100%;
    text-align: center;
  }
}

.title {
  @include h3;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.text {
  @include simple-text;
}
