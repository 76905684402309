@import "../../../styles/description.scss";

.container {
  @include content;
  display: grid;
  grid-column-gap: 116px;

  @include laptop {
    grid-column-gap: 40px;
  }
}

.textPart {
  grid-area: text;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
}

.imageLeft {
  grid-template-areas:
    "image text"
    "image text";

  @include mobile {
    grid-gap: 20px;
    grid-template-areas:
      "title"
      "image"
      "text"
  }
}

.imageRight {
  grid-template-areas:
    "text image"
    "text image";

  @include mobile {
    grid-gap: 20px;
    grid-template-areas:
      "title"
      "image"
      "text"
  }
}

.markdown {
  h2, h3, h4, h5 {
    margin: 0;
  }

  @include mobile {
    text-align: center;
  }
}

.mobileTitle {
  display: none;
  @include mobile {
    display: block;
  }
}

.title {
  margin-bottom: 20px;
  @include mobile {
    display: none;
  }
}

.imageWrapper {
  grid-area: image;
  align-self: end;
  position: relative;
  width: 461px;

  @include mobile {
    width: 100%;
  }

  &.left {
    align-self: start;
    @include laptop {
      align-self: center;
    }
  }

  span {
    position: unset !important;

    .image {
      height: unset !important;
      position: relative !important;
    }
  }
}

.link {
  @include mobile {
    margin: 0 auto;
  }
}
