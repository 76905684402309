@import "../../../styles/description";

.container {
  background: var(--white);
  position: fixed;
  z-index: 10000;
  width: 100vw;
  top: 0;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 16px 16px;

  .content {
    @include content;

    height: 92px;
    display: flex;
    align-items: center;
    padding: 16px 0;

    .textPart {
      display: flex;
      flex-direction: column;

      @include mobile {
        display: none;
      }
    }

    .input {
      width: 317px;
      margin-left: 24px;
      margin-right: 16px;

      @include mobile {
        width: 100%;
        margin: 0;
        pointer-events: none;
      }
    }

    .button {
      @include mobile {
        display: none;
      }
    }
  }
}
