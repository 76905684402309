@import "../../../styles/description.scss";

.warning {
  display: flex;
  padding: 20px 22px;
  background-color: $color-warning-bg;
  border-radius: 10px;

  @include mobile {
    padding: 10px;
  }

  p {
    margin: 0 !important;
  }
}

.warning__image {
  min-width: 20px;
  height: 20px;
  background-image: url("../../../public/icons/warning.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 16px;

  @include mobile {
    margin-right: 8px;
  }
}
