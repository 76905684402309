@import "../../../styles/description.scss";

.container {
  @include content;

  .content {
    * + * {
      margin-top: 12px;
    }

    li + li {
      margin-top: 8px;
    }
  }

  .cards {
    display: flex;
    gap: 24px;
    margin-top: 20px;
    justify-content: space-between;

    @include laptop {
      gap: 20px;
    }

    @include mobile {
      flex-direction: column;
    }

    .card {
      background: var(--white);
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      width: 555px;
      padding: 32px 40px;

      @include laptop {
        width: 480px;
      }

      @include mobile {
        width: 100%;
        padding: 24px 16px;
      }
    }
  }
}
