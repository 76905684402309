@import "../../../styles/description.scss";

.container {
  @include content;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 40px;
  grid-template-areas:
    "title link"
    "chips chips";

  @include mobile {
    grid-template-columns: 100%;
    grid-row-gap: 20px;
    grid-template-areas:
      "title"
      "chips";
  }
}

.title {
  grid-area: title;

  @include mobile {
    text-align: unset;
  }
}

.link {
  grid-area: link;

  @include mobile {
    display: none !important;
  }
}

.chipsContainer {
  grid-area: chips;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.chip {
  user-select: none;
  background-color: var(--white);

  &.active {
    cursor: unset;
  }
}
