.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  .item {
    display: flex;
    gap: 17px;

    .icon {
      min-width: 40px;
      height: 40px;
      color: var(--white);
      border-radius: 50%;
      background-color: var(--corporate);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text p {
      margin-top: 8px;
    }
  }
}
