@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  border-radius: 16px;
  box-shadow: 0 0 0 1pt var(--bg);
  padding: 32px;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 24px 16px;
  }

  .title {
    text-align: start;
    margin-bottom: 24px;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }

    .step {
      min-width: 27%;
      display: flex;
      gap: 24px;

      @include laptop {
        gap: 16px;
      }

      .circle {
        grid-area: circle;
        width: 48px;
        min-width: 48px;
        height: 48px;
        min-height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        background-color: var(--bg-light);
        font-size: 20px;
        font-weight: 700;

        @include mobile {
          font-size: 18px;
          width: 40px;
          height: 40px;
        }
      }

      .content {
        grid-area: content;
        margin-top: 4px;

        @include mobile {
          margin-top: 0;
        }

        p {
          margin-top: 8px;
        }

        p,
        span {
          font-size: 14px;
        }
      }
    }
  }
}
