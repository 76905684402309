@import "../../../styles/description.scss";

.wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.mobile_app_bottom {
  @include content;

  display: flex;
  padding: 30px 0;

  @include mobile {
    padding: 40px 0;
  }
}

.content {
  z-index: 2;

  @include mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.qr {
  width: 132px;
  height: 132px;
  margin-right: 32px;

  @include mobile {
    display: none;
  }
}

.title {
  @include h2;

  margin-bottom: 8px;
}

.subtitle {
  @include simple-text;

  margin-bottom: 16px;
}

.buttons {
  display: flex;

  @include mobile {
    display: none;
  }
}

.link {
  border-radius: 10px;
  background-color: #000;
  width: 146px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & + & {
    margin-left: 16px;
  }

  &__apple {
    background-image: url("../../../public/images/download-app/app_apple.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__google {
    background-image: url("../../../public/images/download-app/app_google.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__huawei {
    background-image: url("../../../public/images/download-app/app_huawei.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;

  @include mobile {
    display: block;
    width: 100%;
  }
}
