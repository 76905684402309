@import "../../../styles/description.scss";

.wrapper {
  position: relative;
  padding-bottom: 120px;

  @include mobile {
    height: unset;
    padding-bottom: 40px;
  }
}

.hidden {
  display: none;
}

.tab__column {
  a {
    color: $color-red;
  }

  max-width: 240px;
  @include laptop {
    max-width: 178px;
  }
}

.input_grz {
  width: 283px;
}

.desktop {
  z-index: 3;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translate(-50%, 0);

  &.halfOfHeight {
    top: 0;
    transform: translate(-50%, -50%);
  }

  @include mobile {
    display: none;
    top: 0;
  }
}

.mobile {
  display: none;

  @include mobile {
    display: block;
  }

  a {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}

.mobile__container {
  span > a {
    color: $color-red;
  }

  display: flex;
  gap: 16px;
  flex-direction: column;

  &.single__tab {
    align-items: flex-start;
  }
}

.single__tab {
  @include h4;
  padding: 0 32px;
  height: 124px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  @include mobile {
    max-width: 375px;
    margin: 0 auto;
    padding: 16px;
    height: unset;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    &.lightPage {
      padding-inline: 8px;

      @include mobile {
        width: 343px;
      }

      @include mobile-mini {
        width: 288px;
      }
    }
  }
}

.backgroundBg {
  background: $color-gray-bgc-2;
}

.backgroundBgLight {
  background: $color-light-disabled;
}

.backgroundWhite {
  background: $color-white;
}

.grzContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
