@import "../../../styles/description.scss";

.container {
  @include content;
  display: grid;

  grid-template-areas:
    'title image'
    'description image';

  @include mobile {
    grid-template-areas:
      'title'
      'image'
      'description';
  }
}

.title {
  @include h2;
  margin-bottom: 40px;
  grid-area: title;

  @include mobile {
    text-align: center;
    margin-bottom: 20px;
  }
}

.descriptionWrapper {
  display: flex;
  justify-content: space-between;
  grid-area: description;
}

.textPart {
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  div:last-of-type {
    margin-top: 40px;
    align-self: start;
  }

  @include mobile {
    text-align: center;
    margin: 20px 0 0 0;

    div:last-of-type {
      margin-top: 20px;
      align-self: stretch;
    }
  }
}

.image {
  width: 295px;
  height: 308px;
  grid-area: image;

  @include laptop {
    width: 252px;
    height: 264px;
  }

  @include mobile {
    width: 277px;
    height: 289px;
    justify-self: center;
  }

  @include mobile-mini {
    justify-self: center;
    width: 230px;
    height: 242px;
  }
}
