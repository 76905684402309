$screen-s: 343px;
$screen-m: 980px;
$screen-l: 1440px;

@mixin laptop {
  @media (max-width: #{$screen-l - 1}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$screen-m - 1}) {
    @content;
  }
}

@mixin mobile-mini {
  @media (max-width: #{$screen-s - 1}) {
    @content;
  }
}
