@import "../../../styles/description.scss";

.disclaimer {
  @include ultra-little-text;
  @include content;
  color: var(--additional);
  padding: 8px 0;
  display: flex;
  width: 100%;
}
