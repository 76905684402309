@import "../../../styles/description.scss";

.item_box:not(:first-of-type) {
  margin-top: 10px;
}

.item_wrapper {
  margin: 0 auto;

  @include mobile {
    max-width: 375px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
}

.item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.item {
  transition: all 0.3s linear;
  background-color: $color-white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.051);
  border-radius: 10px;
  padding: 16px 16px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:hover {
    background-color: $color-light-disabled;
  }

  &:active {
    background-color: $color-gray-bgc-2;
  }

  @include mobile {
    padding: 16px;
  }

  &__title {
    @include h3;
    color: $color-text-main;
    margin-bottom: 0 !important;

    svg {
      margin-left: 8px;
      position: relative;
      right: 0;
    }
  }

  &__content {
    @include simple-text;
    transition: all 0.5s;

    @include mobile {
      @include h4;
      font-weight: 400;
    }

    a {
      @include little-button-text;

      color: $color-red;
      cursor: pointer;

      &:hover {
        color: $color-red-hover;
      }

      &:active {
        color: $color-red-active;
      }
    }

    @include mobile-mini {
      min-width: unset;
      max-width: 288px;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__arrow {
      width: 16px;
      min-width: 16px;
      height: 9px;
      background-image: url("../../../public/icons/background_arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.5s;
    }
  }
}
