@import "../../../styles/description.scss";

$desktopLogoSize: 100px;
$laptopLogoSize: 80px;
$mobileLogoSize: 100px;
$mobileMiniLogoSize: 90px;
$arrowsOffset: 4px;

.sectionContainer {
  background-color: var(--corporate);
}

.container {
  @include content;
  height: 192px;
  color: var(--white);
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 20px;
  grid-template-areas:
          "text contacts logos";
  align-items: center;

  @include mobile {
    justify-items: center;
    height: auto;
    padding-block: 40px;
    text-align: center;
    grid-template-columns: unset;
    grid-auto-rows: min-content;
    grid-gap: 32px;
    grid-template-areas:
            "text"
            "contacts"
            "logos";
  }

  .text {
    grid-area: text;
    width: min-content;
    display: flex;
    flex-direction: column;

    @include mobile {
      width: 100%;
      justify-items: center;
    }

    .textTitle {
      display: flex;
      margin-bottom: 20px;
      align-self: center;
      align-items: center;
      height: max-content;
      width: max-content;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;

      @include mobile {
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 24px;
      }

      .arrows {
        margin-inline: 8px;
        margin-top: $arrowsOffset;
        width: 20px;
        height: 20px;
        background: {
          image: url("/icons/arrows.svg");
          size: 100%;
          repeat: no-repeat;
        }
      }
    }

    .textDescription {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  .contacts {
    grid-area: contacts;
    align-self: end;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;
    grid-gap: 12px;
    align-items: center;

    @include mobile {
      margin-bottom: 0;
      width: 100%;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-gap: 16px;
    }

    .button {
      @include mobile {
        width: 100%;
      }
    }

    .socials {
      display: grid;
      grid-template-columns: min-content;
      grid-auto-flow: column;
      grid-gap: 8px;

      @include mobile {
        justify-content: center;
        align-content: center;
      }
    }
  }

  .logos {
    grid-area: logos;
    position: relative;
    background-image: url("/icons/arrows.svg"), url("/icons/q.svg"), url("/icons/checkMark.svg");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top 0 left 0, top 0 left $desktopLogoSize, top 0 right 0;
    width: $desktopLogoSize * 3;
    height: $desktopLogoSize;

    @include laptop {
      background-position: top 0 left 0, top 0 left $laptopLogoSize, top 0 right 0;
      width: $laptopLogoSize * 3;
      height: $laptopLogoSize;
    }

    @include mobile {
      background-position: top 0 left 0, top 0 left $mobileLogoSize, top 0 right 0;
      width: $mobileLogoSize * 3;
      height: $mobileLogoSize;
    }

    @include mobile-mini {
      background-position: top 0 left 0, top 0 left $mobileMiniLogoSize, top 0 right 0;
      width: $mobileMiniLogoSize * 3;
      height: $mobileMiniLogoSize;
    }
  }
}


