@import "../../../styles/description.scss";

.item_box:not(:first-of-type) {
  margin-top: 10px;
}

.item_wrapper {
  margin: 0 auto;

  @include mobile {
    max-width: 375px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;

  a {
    @include button-text;
    padding-left: 34px;
    margin-right: 140px;
    align-self: center;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      background-image: url("../../../public/icons/document.svg");
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
      height: 26px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @include mobile {
      display: inline-block;
      width: 100%;
      margin-right: 0;
    }
  }

  a + a {
    @include mobile {
      margin-top: 18px;
    }
  }

  h2 {
    margin-top: 40px;

    @include mobile {
      margin-top: 16px;
    }
  }
}

.item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item {
  background-color: $color-white;
  border: 1px solid $color-gray-bgc-2;
  border-radius: 10px;
  padding: 16px 16px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile {
    padding: 16px;
  }

  &__title {
    @include h3;
    color: $color-text-main;
    cursor: pointer;
    margin-bottom: 0 !important;
  }

  &__content {
    @include simple-text;
    transition: all 0.5s;

    h2 {
      @include simple-text;
      margin-bottom: 18px;
    }

    @include mobile {
      @include h4;
      font-weight: 400;
    }

    @include mobile-mini {
      min-width: unset;
      max-width: 288px;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__arrow {
      width: 16px;
      min-width: 16px;
      height: 9px;
      background-image: url("../../../public/icons/background_arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.5s;
    }
  }
}

.delimeter_16 {
  height: 16px;
}
