@import "../../../styles/description.scss";

$desktopLogoSize: 24px;
$mobileLogoSize: 16px;

.sectionContainer {
  @include content;
  min-height: 248px;
  background: var(--bg-light);
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 469px;
  align-items: center;

  @include laptop {
    grid-template-columns: auto 461px;
  }

  @include mobile {
    height: auto;
    grid-template-columns: unset;
  }

  .mainBlock {
    padding: 54px 55px 54px 32px;
    display: flex;
    flex-direction: column;

    @include laptop {
      padding: 54px 26px 54px 24px;
    }

    @include mobile {
      text-align: start;
      padding: 24px 32px;
    }

    @include mobile-mini {
      padding: 24px 28px;
    }

    .mainBlockTitle {
      text-align: start;
      margin-bottom: 16px;
    }
  }

  .redBlock {
    height: 100%;
    background: var(--corporate);
    border-radius: 16px;
    color: var(--white);
    text-align: center;
    padding: 48px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include laptop {
      padding: 48px 36px;
    }

    @include mobile {
      padding: 28px 16px;
    }

    .redBlockTextContainer {
      margin-block: 8px 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        margin-block: 4px 32px;

        .redBlockText {
          @include h4();
        }
      }

      @include mobile-mini {
        margin-block: 6px 28px;

        .redBlockText {
          @include h5();
        }
      }

      .logos {
        margin-left: 6px;
        grid-area: logos;
        position: relative;
        background-image: url("/icons/arrows.svg"), url("/icons/q.svg"), url("/icons/checkMark.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: top 0 left 0, top 0 left $desktopLogoSize, top 0 right 0;
        width: $desktopLogoSize * 3;
        height: $desktopLogoSize;

        @include mobile {
          margin-left: 8px;
          background-position: top 0 left 0, top 0 left $mobileLogoSize, top 0 right 0;
          width: $mobileLogoSize * 3;
          height: $mobileLogoSize;
        }
      }
    }
  }
}
