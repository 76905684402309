@import "../../../styles/description.scss";

.content {
  @include content;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }
}


.title {
  @include h2;

  @include mobile {
    text-align: center;
  }
}

.icon_link {
  &.desktop {
    display: flex;

    @include mobile {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @include mobile {
      display: flex;
      justify-content: center;
    }
  }
}

.container {
  position: relative;
  @include content;

  @include mobile {
    max-width: 375px;
  }

  @media (max-width: 385px) {
    padding-left: 16px;
  }

  @include mobile-mini {
    margin: 0;
    padding: 0 0 0 15px;
    max-width: unset;
  }
}

.displayNone {
  display: none !important;
}


.slider {
  grid-area: slider;
  width: calc(100% - 120px);
  padding: 0 1px !important;

  @include mobile {
    width: 100%;
    padding: 0;
  }

  &__desktop {
    @include mobile {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include mobile {
      display: block;
      width: 343px !important;
    }
  }
}

.arrow {
  height: 100%;
  z-index: 2;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;

  @include mobile {
    align-items: start;
    top: 120px;
    height: calc(100% - 120px);
    display: none;
  }

  svg {
    fill: var(--additional-light);
  }

  &:hover,
  &:focus {
    svg {
      fill: var(--additional);
    }
  }

  &:active {
    svg {
      fill: var(--main);
    }
  }
}

.slide {
  height: auto !important;
}

.arrow__prev {
  left: 0;

  @include mobile-mini {
    left: -10px;
  }
}

.arrow__next {
  right: 0;
  @include mobile-mini {
    right: -10px;
  }
}

.pagination {
  display: none;

  &_bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e5e5e5;
    margin: 0 5px;
    cursor: pointer;

    &_active {
      background: #c5c7c9;
    }
  }

  @include mobile {
    margin: 20px 0;
    position: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}