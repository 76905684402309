@import "../../../../../styles/description.scss";

.container {
  cursor: pointer;
  flex: 1;
  min-width: 364px;
  max-width: 557px;
  height: 168px;

  @include laptop {
    max-width: 480px;
    min-width: 313px;
  }

  @include mobile {
    max-width: none;
    min-width: unset;
    width: 215px;
    height: 192px;
  }

  &.flipped {
    .animationWrapper {
      transform: rotateY(180deg);
    }
  }

  &.big {
    .front,
    .back {
      &:after {
        width: 149px;
        height: 149px;
        bottom: -37px;
        right: -37px;

        @include laptop {
          bottom: -77px;
          right: -77px;
        }
      }
    }

    .icon {
      bottom: 30px;
      right: 30px;

      @include laptop {
        bottom: 13px;
        right: 13px;
      }
    }
  }

  &:hover {
    .front {
      &::after {
        background: var(--corporate-middle);
      }
    }

    .back {
      &::after {
        background: var(--bg-light);
      }
    }
  }

  .front,
  .back {
    position: absolute;
    padding: 20px 32px;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    @include laptop {
      padding: 20px 24px;
    }

    @include mobile {
      padding: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      background: var(--corporate);
      border: 6px solid #ffe6ed;
      border-radius: 50%;
      width: 95px;
      height: 95px;
      bottom: -37px;
      right: -37px;
      transition: background 1s ease-out 100ms;

      @include mobile {
        width: 73px;
        height: 73px;
        bottom: -27px;
        right: -27px;
      }
    }
  }

  .front {
    background: var(--white);
  }

  .back {
    background-color: var(--corporate);
    color: white;
    transform: rotateY(180deg);
    border: 1px solid var(--corporate);

    &:after {
      background: var(--white);
      border-color: var(--corporate-middle);
    }
  }

  .animationWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .title {
    margin-bottom: 20px;
  }

  .text {
    h3 + * {
      margin-top: 8px;
    }
  }

  .icon {
    position: absolute;
    bottom: 13px;
    right: 13px;
    z-index: 1;

    @include mobile {
      bottom: 7px;
      right: 5px;
    }
  }
}
