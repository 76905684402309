.chip {
  user-select: none;
  padding: 10px 16px;
  height: 40px;
  cursor: pointer;
  background: var(--white);
  border-radius: 32px;
  box-shadow: 0 0 16px rgba(28, 41, 61, 0.05), 0 4px 8px rgba(28, 41, 61, 0.06);

  &.active {
    background: var(--corporate);
    box-shadow: none;
  }
}
