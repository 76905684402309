.container {
  border: 1px solid var(--bg);
  padding: 24px 16px;
  border-radius: 16px;

  &.shadow {
    border: none;
    box-shadow: var(--shadow1);
  }
}
