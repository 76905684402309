@import "../../../../../styles/description.scss";

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 42px;

  @include mobile {
    gap: 17px;
  }
}

.title {
  @include content;
}

.desktopCards {
  @include content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7px;
  }

  @include mobile-mini {
    display: none;
  }
}

.slide {
  height: auto;
  width: calc(100vw - 20px);
  max-width: 168px;
}

.mobileCards {
  overflow: visible;
  display: none;
  width: 100vw;
  height: 100%;
  max-width: 375px;
  padding-bottom: 31px !important;
  padding-inline: 10px;

  @include mobile-mini {
    display: block;
  }
}
