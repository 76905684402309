@import "../../../styles/description.scss";

.container {
  height: 400px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include mobile {
    height: 250px;
  }
}

.content {
  @include content;
  z-index: 1;
}

.breadcrumbs {
  position: absolute;
  top: 32px;
  z-index: 2;

  @include mobile {
    top: 0;
  }
}

.title {
  @include h1;
  max-width: 506px;
  margin-bottom: 16px;

  @include mobile {
    max-width: 194px;
  }
}

.subtitle {
  @include simple-text;
  max-width: 506px;

  @include mobile {
    display: none;
  }
}

.mobilesubtitle {
  @include simple-text;
  max-width: 506px;
}
