@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-template-areas:
    "title image"
    "text  image";

  @include mobile {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "title"
      "image"
      "text";
  }
}

.image {
  grid-area: image;
  display: block;
  height: 200px;

  @include laptop {
    height: auto;
    width: 480px;
  }

  @include mobile {
    width: 100%;
  }
}

.title {
  grid-area: title;
  align-self: end;
  margin-bottom: 40px;

  @include mobile {
    margin-block: 0 20px;
    text-align: left;
  }
  @include mobile-mini {
    text-align: center;
  }
}

.text {
  grid-area: text;
  @include simple-text;

  @include mobile {
    margin-top: 20px;
    text-align: center;
  }
}
