@import "../../../../../styles/description.scss";

.container {
  @include mobile {
    overflow: hidden;
  }

  .desktopCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    @include content;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .slide {
    height: auto;

    @include mobile {
      width: calc(100vw - 20px);
      max-width: 232px;
    }
  }

  .slider {
    overflow: visible;
    max-width: 343px;
    padding-bottom: 31px !important;

    @media screen and (max-width: 380px) {
      max-width: 375px;
      padding-inline: 10px;
    }
  }
}
