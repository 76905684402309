@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  display: grid;
  grid-template-columns: auto 210px 365px;
  align-items: center;

  @include laptop {
    grid-template-columns: auto 115px 365px;
  }

  @include mobile {
    grid-template-columns: unset;
    justify-items: center;
  }

  .textBlock {
    padding-right: 42px;

    @include laptop {
      padding-right: 20px;
    }

    @include mobile {
      max-width: 330px;
      padding-right: unset;
      text-align: center;
    }

    .title {
      margin-bottom: 16px;
    }
  }

  .image {
    width: 240px;
    height: 240px;
    position: relative;

    @include mobile {
      margin-block: 32px 24px;
    }
  }

  .buttonBlock {
    width: 100%;
    padding: 24px;
    z-index: 1;
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 0 0 1pt var(--bg), var(--shadow1);
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mobile {
      padding-inline: 16px;
      gap: 16px;
    }

    @include mobile-mini {
      padding-inline: 8px;
    }

    .input {
      margin-bottom: 20px;

      @include mobile {
        margin-bottom: 8px;
      }
    }
  }
}
