.container {
  width: 100%;

  .price {
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}
