@import '../../../../styles/description.scss';

.wrapper {
  @include content;

  display: none;

  @include laptop {
    display: block;
    position: relative;
  }

  .title {
    @include h1;

    margin-bottom: 48px;

    @include mobile {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.pagination {

  &_bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e5e5e5;
    margin: 0 5px;
    cursor: pointer;

    &_active {
      background: #c5c7c9;
    }
  }

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    top: 508px;
    left: 496px;
    z-index: 2;
  }

  @include mobile {
    margin-top: 24px;
    position: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.arrow {
  height: 100%;
  z-index: 2;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;

  @include mobile {
    align-items: start;
    top: 120px;
    height: calc(100% - 120px);
  }

  @include mobile-mini {
    padding: 0;
  }

  svg {
    fill: var(--additional-light);
  }

  &:hover,
  &:focus {
    svg {
      fill: var(--additional);
    }
  }

  &:active {
    svg {
      fill: var(--main);
    }
  }
}

.arrow__prev {
  left: 0;

  @include mobile-mini {
    left: -10px;
  }
}

.arrow__next {
  right: 0;
  @include mobile-mini {
    right: -10px;
  }
}