@import "../../../styles/description.scss";

.wrapper {
  background: var(--bg-light);
  padding: 60px 0;

  @include mobile {
    padding: 40px 0;
  }
}

.container {
  @include content;
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    gap: 20px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 442px;

  @include mobile {
    width: 100%;
    align-items: center;
    gap: 20px;
  }
}

.title {
  @include h1;
  grid-area: title;
  color: var(--main);

  @include mobile {
    text-align: center;
  }
}

.subtitle {
  @include simple-text;
  grid-area: description;
  color: var(--main);

  @include mobile {
    text-align: center;
  }
}

.video {
  grid-area: video;
  max-width: 516px;
  width: 100%;
  height: 290px;
  background: var(--bg);
  border-radius: 10px;

  &_container {
    position: relative;
    width: 516px;

    @include mobile {
      width: 100%;
    }
  }
}
