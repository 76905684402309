@import "../../../styles/description.scss";

.container {
  .title {
    margin-bottom: 40px !important;
    @include content;

    @include mobile {
      margin-bottom: 20px !important;
      text-align: left;
    }
  }
}
