@import "../../../styles/description.scss";

.wrapper {
  padding: 60px 0;

  @include mobile {
    padding: 40px 0;
  }
}

.container {
  @include content;
  display: grid;
  grid-gap: 40px;

  @include mobile {
    grid-gap: 20px;
  }
}

.title {
  @include h2;
  margin: 0;

  @include mobile {
    text-align: center;
  }
}

.materials {
  display: grid;
  grid-gap: 20px;
}
