@import "../../../../../../styles/description.scss";

.container {
  width: 100vw;
  height: 425px;
  background: var(--bg-light);

  @include mobile {
    height: unset;
  }

  .content {
    padding-top: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include content;

    @include mobile {
      flex-direction: column;
      gap: 32px;
      padding: 40px 0;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 382px;

      @include laptop {
        max-width: 340px;
      }

      @include mobile {
        max-width: unset;
        gap: 12px;
      }
    }

    .image {
      width: 550px;
      height: 120px;
      position: relative;

      @include mobile {
        width: 100%;
      }
    }
  }
}
