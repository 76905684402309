@import "../../../styles/description.scss";

.steps {
  @include content;
  display: flex;
  flex-direction: column;

  @include mobile {
    text-align: center;
  }
}

.title {
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 24px;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  @include mobile {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}

.line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url("../../../public/images/steps/dotted_line.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 770px;
  height: 44px;
  z-index: 2;

  @include laptop {
    width: 660px;
  }

  @include mobile {
    display: none;
  }
}
