@import "../../../styles/description.scss";

.container {
  @include content;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include mobile {
    gap: 20px;
  }

  .title {
    grid-area: title;

    @include mobile {
      text-align: unset;
    }
  }

  .partners {
    grid-area: chips;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 20px;

    @include mobile {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row-gap: 12px;
    }
  }
}
