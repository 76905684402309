@import "../../../../../styles/description.scss";

.container {
  display: flex;
  gap: 20px 18px;
  flex-wrap: wrap;
  position: relative;

  @include laptop {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 0;
  }

  > div {
    width: 246px;

    @include mobile {
      width: 100%;
    }
  }

  > button {
    margin-left: auto;
    width: 316px;

    @include mobile {
      width: 100%;
    }
  }

  .insurer {
    @include laptop {
      position: absolute;
      bottom: -40px;
    }

    @include mobile {
      position: initial;
    }
  }
}
