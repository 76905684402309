@import "../../../styles/description.scss";

.desktop {
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: repeat(4, 1fr);

  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.container {
  @include mobile {
    position: relative;
  }
}

.useful_links {
  @include content;
}

.title {
  @include h1;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
    text-align: center;
  }
}

.slide {
  margin-bottom: 20px;
}

.links_block {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  @include little-link;
}

.pagination {
  &_bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e5e5e5;
    margin: 0 5px;
    cursor: pointer;

    &_active {
      background: #c5c7c9;
    }
  }

  margin-top: 20px;
  position: unset;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.arrow {
  height: 100%;
  width: 40px;
  z-index: 2;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s linear;

  svg {
    fill: var(--main);
  }

  &:hover,
  &:focus {
    background-color: rgba(#282d37, 0.1);
  }
}

.arrow__prev {
  left: 0;
}

.arrow__next {
  right: 0;
}
