@import "../../../styles/description.scss";

.wrapper {
  @include content;
  display: flex;
  flex-direction: column;
}

.title {
  @include h2;

  margin-bottom: 40px;

  @include mobile {
    text-align: center;
    margin-bottom: 20px;
  }
}

.content {
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.button {
  align-self: center;
  max-width: 189px;

  @include mobile {
    max-width: 100%;
  }
}
