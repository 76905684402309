@import "../../../styles/description.scss";

.contact_card {
  padding: 32px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.contact_card__title {
  @include h3;
  margin-bottom: 20px;
}

.contact_card__phone {
  @include big-link;

  font-weight: 700;
  margin-bottom: 20px;
  color: $color-red;

  &:hover {
    color: $color-red-hover;
  }

  &:active {
    color: $color-red-active;
  }
}

.contact_card__socials {
  display: flex;
}

.contact_card__telegram,
.contact_card__whatsapp {
  width: 44px;
  height: 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.contact_card__telegram {
  margin-right: 15px;
  background-image: url("../../../public/icons/icon-telegram.svg");
}

.contact_card__whatsapp {
  margin-right: 15px;
  background-image: url("../../../public/icons/icon-wa.svg");
}
