@import "../../../styles/description.scss";

.step {
  text-align: center;
  z-index: 4;
}

.circle {
  @include mobile;

  font-weight: 700;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--primary);
}

.title {
  @include h3;
  margin-bottom: 16px;
}

.content {
  @include simple-text;
}
