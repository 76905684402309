@import "../../../styles/description.scss";

.wrapper__container {
  background-color: var(--bg);
}

.wrapper {
  padding: 60px 0;

  @include mobile {
    overflow: hidden;
    padding: 40px 0;
  }

  @include mobile-mini {
    max-width: 100%;
  }
}

.container {
  @include content;
  grid-area: container;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  width: 100%;

  &.mobile {
    display: none;

    @include mobile {
      display: block;
      position: relative;
    }
  }

  &.desktop {
    @include mobile {
      display: none;
    }
  }
}

.header {
  @include content;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.title {
  @include h1;

  grid-area: title;
  color: var(--color-main);

  @include mobile {
    text-align: center;
  }
}

.icon_link {
  grid-area: iconlink;
  justify-self: end;

  @include mobile {
    justify-content: center;
  }
}

.mini {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.desktopLink {
  @include mobile {
    display: none !important;
  }
}

.mobileLink {
  display: none !important;

  @include mobile {
    display: flex !important;
    @include content;
    margin-top: 20px;
  }
}

.swiper {
  @include content;
  overflow: visible;
  display: none;
  width: auto;
  height: 100%;
  max-width: 343px !important;

  @include mobile {
    display: block;
    padding-bottom: 30px !important;

    .slide {
      width: 168px;
    }
  }

  @include mobile-mini {
    max-width: 288px !important;
    margin-inline: auto;
  }
}
