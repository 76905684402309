@import "../../../../../../styles/description.scss";

.container {
  display: flex;
  border-radius: 16px;
  height: 248px;
  background: var(--bg-light);
  overflow: hidden;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.07));

  @include mobile {
    flex-direction: column;
    height: unset;
  }

  .leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding-left: 32px;
    padding-right: 110px;

    @include laptop {
      padding-left: 24px;
      padding-right: 29px;
      gap: 12px;
    }

    @include mobile {
      width: 100%;
      padding: 32px 16px;
    }
  }

  .centerBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 253px;
    background: var(--corporate);
    color: var(--white);
    flex-shrink: 0;

    .price {
      display: flex;
      align-items: baseline;
      gap: 4px;
      margin-bottom: 32px;
      margin-top: 8px;

      @include mobile {
        margin-bottom: 36px;
      }

      .policePrice {
        @include mobile {
          font-size: 30px;
        }
      }
    }

    @include mobile {
      width: 100%;
      padding: 32px 16px;
      height: 216px;
    }
  }

  .rightBlock {
    width: 430px;
    background: var(--white);
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 44px;
    gap: 33px;

    @include laptop {
      width: 415px;
    }

    @include mobile {
      width: 100%;
      padding-left: 26px;
      padding-top: 36px;
      padding-bottom: 32px;
      gap: 21px;
    }

    @include mobile-mini {
      flex-direction: column;
      padding-left: 0;
      align-items: center;
      text-align: center;
      gap: 24px;
    }

    .shieldTitle {
      margin-top: 32px;
      margin-bottom: 16px;

      @include mobile {
        margin-top: 22px;
      }

      @include mobile-mini {
        margin-top: 14px;
      }
    }
  }
}
