@import "../../../styles/description.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include mobile {
    gap: 20px;
  }

  .text {
    @include content;

    h2 + * {
      margin-top: 12px;
    }
  }

  .desktopCards {
    @include content;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .mobileCards {
    overflow: visible;
    padding-left: 10px;
    width: 100vw;
    height: 100%;
    max-width: 375px;
    padding-bottom: 30px;
  }
}
