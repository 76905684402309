@import "../../../../styles/description.scss";

.map {
  margin: 16px 0;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.symbols {
  display: flex;
  gap: 16px;
  flex: 1;

  .symbol {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 32px;
      height: 32px;
    }

    .hyphen {
      @include simple-text
    }

    .text {
      @include simple-text;
    }

    &:nth-child(1n) {
      width: 158px;
    }

    &:nth-child(2n) {
      width: 133px;
    }

    &:nth-child(3n) {
      width: 200px;
    }

    @include mobile {
      &:nth-child(n) {
        width: auto;
      }
    }
  }

  @include mobile {
    margin-top: 10px;
    flex-direction: column;
  }
}

.corporateLink {
  a {
    color: $color-red;
    @include little-text;

    &:hover {
      color: $color-red-hover
    }

    &:active {
      color: $color-red-active
    }
  }

  &.simple {
    a {
      @include simple-text;
      font-weight: 600;
    }
  }
}

.selectedPlaceWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .description {
    @include little-text;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid $color-gray-bgc-2;
  }

  .address {
    margin-top: 20px;
  }

  .cancelButton {
    width: 211px;

    @include mobile {
      width: 100%;
    }
  }
}

.sendWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include little-text;

  @include mobile {
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
}
