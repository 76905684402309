@import "../../../styles/description.scss";

.wrapper {
  width: 100%;
  border-radius: 10px;
  background-color: $color-white;
  overflow: hidden;

  @include mobile {
    width: 169px;
    height: 172px;
  }
}

.link {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  position: relative;
  @include mobile {
    height: 52px;
  }

  span {
    position: unset !important;

    .cover {
      height: unset !important;
      position: relative !important;
    }
  }

}

.container {
  padding: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;

  .title {
    @include h3;
    color: $color-text-main;
  }

  @include mobile {
    padding: 20px;
  }
}
