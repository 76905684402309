@import "../../../styles/description.scss";

.About {
  @include content;
}

.About__title {
  @include h1;
  margin-top: 0;
  margin-bottom: 40px;

  @include laptop {
    font-weight: 600;
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 30px;
  }
}

.About__table {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.About__table_col {
  width: 50%;

  & + & {
    margin-left: 20px;
  }

  @include mobile {
    width: 100%;

    & + & {
      margin-left: 0;
    }
  }
}

.About__text {
  margin-bottom: 10px;

  a {
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    color: $color-red;

    &:hover {
      color: $color-red-hover;
    }

    &:active {
      color: $color-red-active;
    }

    @include laptop {
      font-weight: 600;
    }
  }
}

.About__text_bold {
  @include h3;
  margin-bottom: 10px;
}

.About__text_link {
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  color: $color-red;

  &:hover {
    color: $color-red-hover;
  }

  &:active {
    color: $color-red-active;
  }

  @include laptop {
    font-weight: 600;
  }
}

.mb_20 {
  margin-bottom: 20px;
}
