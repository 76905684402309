@import "../../../styles/description.scss";

.wrapper {
  height: 312px;
  width: 100%;
  padding-top: 30px;
  background-color: $color-light-disabled;

  @include mobile {
    height: 222px;
    padding-top: 0;
  }
}

.content {
  @include content;
}

.title {
  @include h1;
  margin: 0;
  width: 557px;

  @include laptop {
    width: 560px;
  }

  @include mobile {
    width: 174px;
  }
}

.subtitle {
  @include simple-text;

  max-width: 506px;

  @include mobile {
    display: none;
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 123px;

  @include mobile {
    width: 161px;
    height: 66px;
  }

  @include mobile-mini {
    width: 107px;
    height: 44px;
  }
}

.logo {
  width: 100%;
}

.container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}