@import "../../../styles/description.scss";

.policy_genuine {
  @include content;
  display: grid;
  grid-template-columns: 1fr 370px;
  grid-column-gap: 20px;
  grid-template-areas:
    "title image"
    "row-1 image"
    "row-2 image"
    "list link";

  @include laptop {
    grid-template-columns: 1fr 313px;
  }

  @include mobile {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "row-1"
      "row-2"
      "list"
      "link";
  }
}

.title {
  margin-bottom: 40px;
  grid-area: title;

  @include mobile {
    margin-bottom: 20px;
  }
}

.row_1 {
  grid-area: row-1;

  @include mobile {
    margin-bottom: 20px;
  }
}

.row_2 {
  grid-area: row-2;

  @include mobile {
    margin-bottom: 20px;
  }
}

.row_1,
.row_2 {
  @include simple-text;

  a {
    font-weight: 700;
    color: $color-red;

    &:hover {
      color: $color-red-hover;
    }

    &:active {
      color: $color-red-active;
    }
  }

  margin-bottom: 16px;
}

.row {
  @include mobile {
    margin-bottom: 20px;
  }
}

.image {
  width: 366px;
  height: 209px;
  grid-area: image;

  @include laptop {
    width: 313px;
    height: 179px;
  }

  @include mobile {
    margin-bottom: 20px;
    width: 343px;
    height: 196px;
  }

  @include mobile-mini {
    width: 288px;
    height: 164px;
  }
}

.icon_link {
  grid-area: link;
  align-self: end;
  justify-self: end;
  white-space: pre;

  @include mobile {
    justify-self: center;
  }
}
