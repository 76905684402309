@import "../../../styles/description.scss";

.wrapper {
  display: flex;
  height: 103px;
  border: 1px solid $color-gray-bgc-2;
  border-radius: 10px;
  overflow: hidden;

  @include mobile {
    flex-direction: column;
    height: 100%;
  }
}

.content {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  width: 100%;
  background: $color-white;

  @include mobile {
    height: 100px;
  }
}

.image {
  width: 269px;

  @include laptop {
    width: 230px;
  }

  @include mobile {
    width: 100%;
    height: 132px;
  }
}

.title {
  @include h3;
  margin: 0;
}

.link {
  @include little-button-text;
  color: $color-red !important;
}
