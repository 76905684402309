@import "../../../styles/description.scss";

.partners {
  background-color: $color-light-disabled;
}

.wrapper {
  padding: 60px 0;
  overflow: hidden;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 40px;
  grid-template-areas:
    "title"
    "slider";

  @include mobile {
    grid-row-gap: 20px;
    padding: 0 0 40px 0;
  }
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  @include h1;
  grid-area: title;

  @include mobile {
    justify-self: center;
  }
}

.slider {
  grid-area: slider;
  position: relative;
  margin: 0 auto;
  padding: 0 40px;
  justify-content: space-between;
  user-select: none;

  @include mobile {
    padding: 0;
  }

  &Desktop {
    padding: 0;

    @include mobile {
      display: none;
    }

    > div {
      margin: 0 auto !important;
    }
  }

  &Mobile {
    display: none;

    @include mobile {
      display: block;
      width: 343px !important;
    }
  }

  &.withBullet {
    padding-bottom: 30px !important;
  }
}

.wrapper,
.slider {
  max-width: 1134px;
  width: 100%;

  @include laptop {
    max-width: 980px;
  }

  @include mobile {
    max-width: 343px;
  }

  @include mobile-mini {
    max-width: 280px;
  }
}

.pagination {
  display: none;
  margin-top: 20px;
  position: unset;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @include mobile {
    display: flex;
  }

  &Bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e5e5e5;
    margin: 0 5px;
    cursor: pointer;

    &Active {
      background: #c5c7c9;
    }
  }
}

.arrow {
  height: 100%;
  width: 40px;
  z-index: 2;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s linear;

  @include mobile {
    display: none;
  }

  svg {
    fill: $color-additional-light;

    &:hover {
      fill: $color-additional;
    }

    &:focus {
      fill: $color-text-main;
    }
  }

  &Prev {
    left: 0;
  }

  &Next {
    right: 0;
  }
}

.card {
  width: 132px !important;
  background: var(--bg-ligth) !important;
  border: none !important;

  &.link:hover {
    background: var(--white) !important;
  }
}
