@import "../../../styles/description.scss";

.content {
  @include content;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  @include mobile {
    grid-template-columns: 1fr;
    text-align: center;
    grid-gap: 20px;
  }
}

.mapView {
  @include content;
  width: 100%;
  height: 300px;
  margin-top: 40px;

  @include mobile {
    max-width: 375px !important;
    height: 380px;
    margin-top: 20px;
  }
}