@import "../../../styles/description.scss";

.sectionContainer {
  @include content;
  padding-block: 31px;
  border-block: 1px solid var(--bg);

  @include mobile {
    padding-block: 19px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .title {
      text-align: start;
    }

    svg {
      min-width: 24px;
    }
  }

  .content {
    margin-top: 0;
    height: 0;
    overflow: hidden;
    gap: 20px;
    column-count: 3;

    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &.show {
      margin-top: 32px;
      height: max-content;

      @include laptop {
        margin-top: 40px;
      }

      @include mobile {
        margin-top: 20px;
      }
    }

    .linksBlock ~ .linksBlock {
      margin-top: 32px;

      @include mobile {
        margin-top: 0;
      }
    }

    .linksBlock {
      break-inside: avoid;

      .titleLinkWrapper {
        display: flex;
        align-items: center;

        .dot {
          min-width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--primary);
          margin-right: 12px;
        }

        .titleLink {
          @include h4();
        }
      }

      .titleLinkWrapper ~ .links .linkWrapper:first-child {
        margin-top: 16px;

        @include mobile {
          margin-top: 8px;
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .linkWrapper {
          @include h5();
          display: flex;
          align-items: center;

          .dash {
            color: var(--additional-light);
            text-align: right;
            margin-inline: 20px 12px;

            @include laptop {
              margin-right: 8px;
            }

            @include mobile {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}
