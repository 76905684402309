@import "../../../styles/description.scss";

.container {
  position: relative;
}

.desktop {
  display: flex;

  @include laptop {
    color: red;
    display: none;
  }
}

.laptop {
  display: none;

  @include laptop {
    display: block;
  }
}

.wrapper {
  flex-direction: column;
  align-items: flex-end;
}

.sticky {
  position: sticky;
  width: 100%;
  height: 755px;
  top: 60px;
  display: flex;
  align-items: center;

  &::after {
    content: " ";
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $color-gray-bgc-2;
    border-radius: 835px 0 0 835px;
  }
}

.content {
  @include content;
}
